import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    width: 124.2px;
    margin: 0 auto 0;
    display: flex;
    justify-content: space-between;
`;

const Icon = styled.img`
    max-height: 27px;
`;

const SocialMediaIcons = ({ className }) => (
  <IconContainer className={className}>
    <a href="https://twitter.com/portlmedia/" target="_blank" rel="noopener noreferrer">
      <Icon src="/assets/twitterIcon.png" />
    </a>
    <a href="https://www.instagram.com/portlmedia/" target="_blank" rel="noopener noreferrer">
      <Icon src="/assets/instagramIcon.png" />
    </a>
    <a href="https://www.facebook.com/portlmedia/" target="_blank" rel="noopener noreferrer">
      <Icon src="/assets/facebookIcon.png" />
    </a>
  </IconContainer>
);

export default SocialMediaIcons;
