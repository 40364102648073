import React from 'react';
import styled from 'styled-components';

const PortlContainer = styled.div`
    font-family: Montserrat;
    font-size: 10px;
    color: #fafafa;
    text-transform: initial;
`;

const PortlCopyRight = ({ className }) => (
  <PortlContainer className={className}>
    2020 Portl Media Inc.
  </PortlContainer>
);

export default PortlCopyRight;
