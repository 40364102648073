import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ordinal from 'ordinal';

// components
import FormsContainer from '../../components/FormsContainer';

// compositions
import QuizRankTemplate from '../../compositions/QuizRankTemplate';
import QuizOptionTemplate from '../../compositions/QuizOptionTemplate';
import QuizNameSubTemplate from '../../compositions/QuizNameSubTemplate';

// utils
import { initConnection } from '../../utils/socket';

const Background = styled.div`
    min-height: 100vh;
    font-family: 'Mountains of Christmas', cursive;
    background-image: url('/assets/christmasBackground.png');
    background-size: cover;
    padding-top: 6vh;
    color: #cd0e11;
    text-align: center;
`;

const Container = ({ children, onClick }) => (
  <Background onClick={onClick}>
    <FormsContainer>
      {children}
    </FormsContainer>
  </Background>
);

const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    text-align: center;
`;

const Text = styled.p`
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.61;
    text-align: center;
    color: #1f2639;
    padding-top: 16px;

    span {
        font-weight: bold;
    }
`;

const ButtonWrapper = styled.div`
    width: 189px;
    height: 71px;
    box-shadow: 3px 5px 7px 0 rgba(0, 0, 0, 0.16);
    background-image: url('${({ src }) => src}');
    background-size: cover;
    border-radius: 10px;
    margin: 30px auto;
`;

const Button = styled.div`
    width: 189px;
    height: 71px;
    border-radius: 10px;
    box-shadow: 3px 5px 7px 0 rgba(0, 0, 0, 0.16);
    background-color: #cd0e11;
    text-transform: uppercase;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 39px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 1.85;
    margin: 30px auto;
`;

const QuizCharity = ({ onStepChange, isSocketConnect }) => (
  <Container onClick={onStepChange}>
    <Title>
      Play for Charity
    </Title>
    <Text>
      What if the holidays don&apos;t come from a store?
      Perhaps the holidays mean a little bit more!
    </Text>
    <Text>
      The Grinch was spot on! Portl will be donating $1 for every playthrough
      of this quiz to Sick Kids in order to spread cheer for those who need it most.
    </Text>
    {(isSocketConnect)
      ? <ButtonWrapper src="/assets/quizNextBtn.png" />
      : (
        <Text>
          <span>Please scan the QR code for the game on the tablet again to play</span>
        </Text>
      )}
  </Container>
);

const QuizInfo = ({ handleStart }) => (
  <Container onClick={handleStart}>
    <Title>
      The Rules
    </Title>
    <Text>
      You will see a blurry image from a holiday movie
      that will gradually become clearer over the span of
      {' '}
      {' '}
      <span>10 seconds</span>
      .
    </Text>
    <Text>
      Choose the answer that best matches up with the image before it&apos;s revealed!
    </Text>
    <Text>
      Faster answers get more points!
    </Text>
    <Button>
      Let&apos;s Go!
    </Button>
  </Container>
);

const QuizOptionTemplateWrapper = styled(QuizOptionTemplate)`
    margin-top: 30px;
    font-weight: bold;
`;

const QuizGame = ({ question, total, onClickOption }) => (
  <Container>
    <Title>
      Question
      {' '}
      {question}
      /
      {total}
    </Title>
    <Text>
      Refer to the tablet for the question and then select your answer below.
    </Text>
    <QuizOptionTemplateWrapper onClickOption={onClickOption} bckColor="#cd0e11" color="#ffffff" />
  </Container>
);

const Subtitle = styled(Title)`
    font-size: 25px;
`;

const Space = styled.div`
    height: 10px;
`;

const QuizScore = ({ info, setInfo, onSubmit }) => (
  <Container>
    <Title>
      Results
    </Title>
    <Space />
    <Subtitle>
      Correctly Answered:
      {info.correct}
    </Subtitle>
    <Subtitle>
      Score:
      {info.score}
    </Subtitle>
    <QuizNameSubTemplate info={info} setInfo={setInfo} themeColor="#cd0e11" stdColor="#fafafa" onSubmit={onSubmit} />
  </Container>
);

const PlayAgainBtn = styled.div`
    height: 57px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #cd0e11;
    font-size: 25px;
    font-weight: bold;
    color: #fafafa;
    line-height: 2.2;
`;

const QuizLeaderboard = ({ info, ranks, playAgain }) => (
  <Container>
    <Title>
      You Rank
      {' '}
      {ordinal(ranks.position + 1)}
      !
    </Title>
    <Subtitle>
      Score:
      {info.score}
    </Subtitle>
    <QuizRankTemplate ranks={ranks} />
    <PlayAgainBtn onClick={playAgain}>
      Play Again!
    </PlayAgainBtn>
  </Container>
);

const QuizChristmas = ({ privateCode, tabletIMEI }) => {
  const [isSocketConnect, setSocketConnection] = useState(false);
  const [info, setInfo] = useState({
    name: '', score: 0, correct: 0, content: '',
  });
  const [question, setQuestion] = useState(0);
  const [ranks, setRanks] = useState({ top10: [], position: 0 });
  const [step, setStep] = useState(0);
  const [disableClick, setDisableClick] = useState(false);

  const total = useRef(0);
  const socketRef = useRef(initConnection());
  const query = window.location.pathname.split('/');
  const code = privateCode || query.pop();
  const IMEI = tabletIMEI || query.pop();

  const playAgain = () => {
    setInfo({
      correct: 0, score: 0, name: '', content: '',
    });
    setStep(1);
    setRanks({ top10: [], position: '' });
  };

  useEffect(() => {
    const socket = socketRef.current;
    return () => socket.emit('desactiveGameListeners');
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on('tabletConnected', () => {
      setSocketConnection(true);
    });

    socket.on('questionNumber', (number) => {
      setQuestion(number);
      setDisableClick(false);
    });

    socket.on('scoreInfo', (tabletInfo) => {
      setInfo({ ...tabletInfo });
      setStep(3);
    });

    socket.on('leaderboard', ({ top10, position }) => {
      setDisableClick(false);
      setRanks({ top10, position });
      setStep(4);
    });

    socket.on('gameRestarted', () => {
      playAgain();
    });

    socket.on('notConnected', (msg) => {
      setSocketConnection(false);
      console.log(msg);
    });

    socket.on('disconnect', () => {
      setSocketConnection(false);
    });

    socket.emit('joinGame', { code, IMEI });

    return () => {
      socket.off('tabletConnected');
      socket.off('questionNumber');
      socket.off('notConnected');
      socket.off('quizStarted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef.current]);

  const handleStart = (e) => {
    e.stopPropagation();
    e.preventDefault();

    socketRef.current.on('quizStarted', (totalQuestions) => {
      total.current = totalQuestions;
      setStep(2);
    });
    socketRef.current.emit('startQuiz');
  };

  useEffect(() => {
    socketRef.current.once('nextStepChanged', () => {
      setStep((prev) => prev + 1);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => socketRef.current.off('nextStepChanged');
  }, [step]);

  const handleSubmission = (e) => {
    e.stopPropagation();
    e.preventDefault();
    socketRef.current.emit('persistScore', info);
  };

  const handleStepChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    socketRef.current.emit('nextStep');
  };

  const handleClickOption = (opt) => {
    if (disableClick) return;

    setDisableClick(true);
    socketRef.current.emit('answerQuestion', { question, opt });
  };

  const handlePlayAgain = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (disableClick) return;

    setDisableClick(true);
    socketRef.current.emit('playAgain');
  };

  switch (step) {
    case 1:
      return <QuizInfo handleStart={handleStart} />;
    case 2:
      return (
        <QuizGame
          question={question}
          total={total.current}
          onClickOption={handleClickOption}
        />
      );
    case 3:
      return <QuizScore setInfo={setInfo} info={info} onSubmit={handleSubmission} />;
    case 4:
      return (
        <QuizLeaderboard
          info={info}
          ranks={ranks}
          socket={socketRef.current}
          playAgain={handlePlayAgain}
        />
      );
    default:
      return <QuizCharity onStepChange={handleStepChange} isSocketConnect={isSocketConnect} />;
  }
};

export default QuizChristmas;
