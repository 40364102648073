import React from 'react';
import styled from 'styled-components';

const SubmitLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

const SubmitLoadingContainer = ({ children }) => (
  <SubmitLoading>
    {children}
  </SubmitLoading>
);

export default SubmitLoadingContainer;
