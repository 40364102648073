/* eslint-disable prefer-template */
// template strings currently break linting inside imports https://github.com/babel/babel/issues/10904
import React from 'react';
import loadable from '@loadable/component';

const DynamicComponent = loadable(({ path }) => (
  import('../../' + path)
    .catch(() => import('../../compositions/ContentTabletOnly'))),
{
  fallback: <div>Loading...</div>,
  cacheKey: (props) => props.path,
}); // path is the location where the file is located starting on the src file.

export default DynamicComponent;
