import React, {
  forwardRef, useImperativeHandle,
  useRef, useState,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

const SvgContainer = styled.div`
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 20px;
    pointer-events: none;
`;
const SliderContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
    height: 100%;
`;
const SliderFill = styled.div`
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ height }) => (height < 3 ? '3' : height)}%;
    background: #d8d7d7;
    width: 170px;
`;
const StyledSlider = styled.input`
    padding: 0;
    margin: 0;
    width: 130px;
    height: 291px;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 20px;
    -webkit-appearance: slider-vertical;
    &:focus{
        outline: none;
    }
    &::-webkit-slider-runnable-track {
        width: 170px;
        -webkit-appearance: none;
        height: 100% !important;
        cursor: pointer;
        background: #212121;
        border-radius: 20px;
    }
    &::-moz-range-track {
        width: 170px;
        -webkit-appearance: none;
        height: 100% !important;
        cursor: pointer;
        background: #212121;
        border-radius: 20px;
    }
    &::-ms-track {
        width: 170px;
        -webkit-appearance: none;
        height: 100% !important;
        cursor: pointer;
        background: #212121;
        border-radius: 20px;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 170px;
        height: 1px;
        opacity: 0;
    }
    &::-moz-range-thumb {
        -webkit-appearance: none;
        width: 170px;
        height: 1px;
        opacity: 0;
    }
    &::-ms-thumb {
        -webkit-appearance: none;
        width: 175px;
        height: 1px;
        opacity: 0;
    }
`;

const SettingsSlider = ({
  onAfterChange, svg: Svg, disabled = false, initialValue = 50,
}, ref) => {
  const onChangeRef = useRef(debounce((value) => onAfterChange(value), 800));

  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    onChangeRef.current(e.target.value);
  };

  // if you want to handle state change from parent
  useImperativeHandle(ref, () => ({
    changeValue: setValue,
  }));

  return (
    <SliderContainer>
      <StyledSlider
        type="range"
        orient="vertical"
        min={0}
        max={100}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <SliderFill height={value} />
      {Svg && (
      <SvgContainer>
        <Svg />
      </SvgContainer>
      )}
    </SliderContainer>
  );
};

// use forwardRef if using imperitive handle
export default forwardRef(SettingsSlider);
