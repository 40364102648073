import React from 'react';
import styled from 'styled-components';

import QuizOpt from '../../components/QuizOpt';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const QuizOptWrapper = styled(QuizOpt)`
    margin-bottom: 25px;
`;

const QuizOptionTemplate = ({
  onClickOption, bckColor, color, className,
}) => {
  const handleOnClick = (e, number) => {
    e.stopPropagation();
    e.preventDefault();
    onClickOption(number);
  };

  return (
    <Wrapper className={className}>
      <QuizOptWrapper bckColor={bckColor} color={color} name="A" onClick={(e) => handleOnClick(e, 0)} />
      <QuizOptWrapper bckColor={bckColor} color={color} name="B" onClick={(e) => handleOnClick(e, 1)} />
      <QuizOpt bckColor={bckColor} color={color} name="C" onClick={(e) => handleOnClick(e, 2)} />
      <QuizOpt bckColor={bckColor} color={color} name="D" onClick={(e) => handleOnClick(e, 3)} />
    </Wrapper>
  );
};

export default QuizOptionTemplate;
