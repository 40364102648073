import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Components
import FormsContainer from '../../components/FormsContainer';
import Button from '../../components/Button';

// utils
import { initConnection } from '../../utils/socket';

const Background = styled.div`
    min-height: 100vh;
    font-family: 'Playfair Display', serif;
    font-size: 21px;
    line-height: 1.61;
    background-color: #006fd5;
    padding-top: 6vh;
    color: #f2f2f2;;
    text-align: center;
`;

const Title = styled.h1`
    font-family: FeniceStd;
    font-size: 36px;
    font-style: oblique;
    line-height: 1.31;
    font-weight: 400;
`;

const QuizLogo = styled.img`
    width: 240px;
    height: 132px;
    margin: 0 auto;
`;

const Intro = styled.p`
    max-width: 330px;
    margin: 0 auto;
    padding-bottom: 45px;
`;

const ButtonStart = styled.div`
    width: 320px;
    height: 99px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    font-size: 27px;
    font-weight: 700;
    color: #0982f1;
    line-height: 3.5;
    margin: 0 auto;
`;

const Space = styled.div`
    height: 50px;
`;

const Start = ({ onStartQuiz, isSocketConnect }) => (
  <>
    <Title>The quiz about</Title>
    <QuizLogo src="/assets/quiz-nothing-title.png" />
    <Intro>
      Alright so this is going to get a bit awkward. There are no wrong answers.
      It&apos;s really not even a quiz, it&apos;s nothing, kind of like that sitcom.
      But after each question you&apos;ll get to see how other riders answered,
      so maybe that&apos;s something?
    </Intro>
    {isSocketConnect
      ? (
        <>
          <ButtonStart onClick={onStartQuiz}>
            Okay, let&apos;s go!
          </ButtonStart>
          <Space />
        </>
      )
      : (
        <Intro>
          Please scan the QR code on the tablet
        </Intro>
      )}
  </>
);

const QuestionTitle = styled.div`
    margin: 0 auto;
    width: 272px;
    height: 144.4px;
    background-image: url('/assets/yellow-elipse.png');
    background-size: cover;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.74);
    font-family: FeniceStd;
    font-size: 46px;
    font-style: oblique;
    line-height: 3.0;
    letter-spacing: -2.48px;
    color: #dc0921;
`;

const QuestionTips = styled.p`
    padding-top: 18px;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 46px;
`;

const Option = styled.div`
    margin: 0 25px 35px;
    width: 118px;
    height: 118px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    font-size: 68px;
    font-weight: 700;
    line-height: 1.7;
    color: #0982f1;

    ${(props) => props.showResults && `
        font-size: 45px;
        line-height: 2.6;
        background: linear-gradient(0deg, #bee0ff ${props.percent}%, #ffffff ${props.percent}%);
    `}
`;

const ButtonWrapper = styled(Button)`
    width: ${({ isBonus }) => (isBonus ? '285px' : '199px')};
    height: 90px;
    border-radius: 20px;
    background-color: #ffffff;
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    font-weight: 700;
    color: #0982f1;
    margin: 10px auto 0;
`;

const Quiz = ({
  question, total, onSubmit, votes, onNext, isBonus, setShowResults, showResults,
}) => {
  const [totalVotes, setTotalVotes] = useState(1);
  const [indVotes, setIndVotes] = useState([]);

  useEffect(() => {
    setShowResults(false);
    setIndVotes(votes);

    let totalVts = 1;
    for (let i = 0; i < 4; i += 1) {
      if (votes[i]) {
        totalVts += votes[i];
      }
    }

    setTotalVotes(totalVts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votes]);

  const handleOption = (opt) => {
    const updatedVotes = indVotes;
    updatedVotes[opt] += 1;
    setIndVotes(updatedVotes);
    onSubmit(opt);
    setShowResults(true);
  };
  const buttonText = showResults ? 'Next' : 'Skip';

  return (
    <>
      <QuestionTitle>
        {(isBonus)
          ? 'Bonus!'
          : `Question ${question + 1}/${total - 1}`}
      </QuestionTitle>
      <QuestionTips>
        {(showResults)
          ? 'Please tap anywhere on the screen to go to the next question.'
          : 'Refer to the tablet for the question and then select your answer below.'}
      </QuestionTips>
      <Container>
        <Option
          onClick={() => handleOption(0)}
          showResults={showResults}
          percent={(indVotes[0] / totalVotes) * 100}
        >
          {(showResults)
            ? `${Math.trunc((indVotes[0] / totalVotes) * 100)}%`
            : 'A'}
        </Option>
        <Option
          onClick={() => handleOption(1)}
          showResults={showResults}
          percent={(indVotes[1] / totalVotes) * 100}
        >
          {(showResults)
            ? `${Math.trunc((indVotes[1] / totalVotes) * 100)}%`
            : 'B'}
        </Option>
        <Option
          onClick={() => handleOption(2)}
          showResults={showResults}
          percent={(indVotes[2] / totalVotes) * 100}
        >
          {(showResults)
            ? `${Math.trunc((indVotes[2] / totalVotes) * 100)}%`
            : 'C'}
        </Option>
        <Option
          onClick={() => handleOption(3)}
          showResults={showResults}
          percent={(indVotes[3] / totalVotes) * 100}
        >
          {(showResults)
            ? `${Math.trunc((indVotes[3] / totalVotes) * 100)}%`
            : 'D'}
        </Option>
      </Container>
      <ButtonWrapper onClick={() => showResults || onNext()} isBonus={isBonus}>
        {(isBonus)
          ? 'Thanks for playing!'
          : buttonText}
      </ButtonWrapper>
      <Space />
    </>
  );
};

const NothingQuiz = ({ privateCode, tabletIMEI }) => {
  const [isSocketConnect, setSocketConnection] = useState(false);
  const [question, setQuestion] = useState(0);
  const [votes, setVotes] = useState([]);
  const [isBonus, setIsBonus] = useState();
  const [showResults, setShowResults] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const total = useRef(0);
  const socketRef = useRef(initConnection());
  const query = window.location.pathname.split('/');
  const code = privateCode || query.pop();
  const IMEI = tabletIMEI || query.pop();

  useEffect(() => {
    const socket = socketRef.current;
    return () => socket.emit('desactiveGameListeners');
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on('tabletConnected', () => {
      setSocketConnection(true);
    });
    socket.on('questionAndVotes', (number, numOfVotes) => {
      setDisableButton(false);
      setQuestion(number);
      setVotes(numOfVotes);
      if (number + 1 === total.current) setIsBonus(true);
    });
    socket.on('notConnected', (msg) => {
      setSocketConnection(false);
      console.log(msg);
    });

    socket.emit('joinGame', { code, IMEI });

    socket.on('disconnect', () => {
      setSocketConnection(false);
    });

    return () => {
      socket.off('tabletConnected');
      socket.off('questionNumber');
      socket.off('notConnected');
      socket.off('quizStarted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef.current]);

  const handleStart = () => {
    socketRef.current.on('quizResultStarted', (totalQuestions, numOfvotes) => {
      total.current = totalQuestions;
      setVotes(numOfvotes); // vote from 1st question
    });
    socketRef.current.emit('startQuiz');
  };

  const handleSubmit = (opt) => {
    socketRef.current.emit('answerQuestion', { question, opt });
  };

  const handleNext = () => {
    if (disableButton) return;
    setDisableButton(true);
    socketRef.current.emit('nextQuestion');
  };

  const handleClickResult = () => {
    if (!showResults) return;
    handleNext();
  };

  return (
    <Background onClick={handleClickResult}>
      <FormsContainer>
        {votes.length > 0
          ? (
            <Quiz
              total={total.current}
              question={question}
              onSubmit={handleSubmit}
              votes={votes}
              onNext={handleNext}
              isBonus={isBonus}
              setShowResults={setShowResults}
              showResults={showResults}
            />
          )
          : <Start onStartQuiz={handleStart} isSocketConnect={isSocketConnect} />}
      </FormsContainer>
    </Background>
  );
};

export default NothingQuiz;
