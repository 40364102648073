import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
    position: relative;
    width: fit-content;
    margin: 0 auto;
`;

const InputName = styled.input`
    letter-spacing: 60px;
    text-indent: 25px;
    background: transparent;
    width: 230px;
    border: none;
    font-size: 21px;
    color: ${({ color }) => color || '#ffffff'};
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
`;

const WhiteLine = styled.div`
    width: 60px;
    height: 2px;
    background-color: ${({ color }) => color || '#ffffff'};
    position: absolute;
    bottom: -4px;
    left: ${({ left }) => left}px;
`;

const QuizNameInput = ({
  name, handleNameChange, large, color,
}) => (
  <InputContainer>
    <InputName
      id="scoreName"
      type="text"
      maxLength={3}
      onChange={handleNameChange}
      value={name}
      large={large}
      color={color}
    />
    <WhiteLine left={4} color={color} />
    <WhiteLine left={85} color={color} />
    <WhiteLine left={170} color={color} />
  </InputContainer>
);

export default QuizNameInput;
