import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 118px;
    height: 118px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 68px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ bckColor, color }) => `
        background-color: ${bckColor};
        color: ${color};
    `}
`;

const QuizOpt = ({
  name, color = '#fefefe', bckColor = '#1fb6ff', onClick, className,
}) => (
  <Wrapper bckColor={bckColor} color={color} onClick={onClick} className={className}>
    {name}
  </Wrapper>
);

export default QuizOpt;
