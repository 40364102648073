import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import FormsContainer from '../../components/FormsContainer';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

// Compositions
import Footer from '../../compositions/Footer';
import GameSubmitWin from '../../compositions/GameSubmitWin';

const Background = styled.div`
  background: url('/assets/easter/easterBackground.webp') center top no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.div`
  font-family: 'Dela Gothic One', cursive;
  -webkit-text-stroke: 1.5px #dd65ba;
  color: #fafafa;
  font-size: 27px;
  width: fit-content;
  margin: 0 auto 30px;
`;
const Description = styled.div`
  width: 302px;
  font-size: 18px;
  color: #f1f8ff;
  padding-bottom: 20px;
`;
const DescriptionSubmitted = styled(Description)`
  text-align: center;
  & > span {
    text-decoration: underline;
  }
`;
const FieldName = styled.div`
  font-size: 15px;
  color: #f1f8ff;
`;
const ErrorField = styled.div`
  color: #d80f4d;
  font-size: 10px;
  font-weight: bold;
  margin-top: 5px;
`;
const InputFieldStyled = styled(InputField)`
  margin-top: 8px;
  margin-bottom: 18px;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
`;
const ButtonContainer = styled(Button)`
  font-family: 'Dela Gothic One', cursive;
  line-height: 0px;
  text-transform: uppercase;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.63);
  border: solid 4px #fff;
  border-radius: 10px;
  height: 50px;
  background-color: #2b7c20;
  font-size: 26px;
  letter-spacing: 2px;
  margin-top: 12px;
  margin-bottom: 17px;
`;
const StyledFormsContainer = styled(FormsContainer)`
  padding-top: 25px;
`;
const ConfirmText = styled.div`
  color: #fafafa;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 10px auto 20px;
`;
const nameRegExp = /^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegExp, 'Please enter a valid name.')
    .required('Please enter a valid name.'),
  lastName: Yup.string()
    .matches(nameRegExp, 'Please enter a valid name.')
    .required('Please enter a valid name.'),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter a valid email.'),
  prizeType: Yup.string()
    .required('Please select a prize type'),
});

const Logos = styled.div`
  text-align: center;
  margin: 80px auto 0;
  font-weight: 700;
  color: #f9f9f9;
  font-size: 41px;
`;

const KHPLogo = styled.div`
  background: url('/assets/easter/khp_logo_white.webp') no-repeat center;
  background-size: cover;
  width: 400px;
  height: 40px;
`;

const BackgroundTemplate = ({ children }) => (
  <Background>
    <StyledFormsContainer>
      <Title>You Saved Easter!</Title>
      {children}
    </StyledFormsContainer>
    <Logos>
      portl
      <KHPLogo />
    </Logos>
    <Footer color="none" />
  </Background>
);

const FormComponent = ({ onSubmit }) => (
  <>
    <Description>
      For your hard work, you&apos;ll get a $10 gift card of your choosing,
      and Portl will donate $10 to Kid&apos;s Help Phone.
    </Description>
    <Description>
      Please enter your details below to claim your prize.
    </Description>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        prizeType: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values }) => (
        <Form>
          <FieldName>First Name</FieldName>
          {errors.firstName && touched.firstName && (
            <ErrorField>{errors.firstName}</ErrorField>)}
          <InputFieldStyled id="firstName" name="firstName" />
          <FieldName>Last Name</FieldName>
          {errors.lastName && touched.lastName && (
            <ErrorField>{errors.lastName}</ErrorField>)}
          <InputFieldStyled
            id="lastName"
            name="lastName"
          />
          <FieldName>Email Address</FieldName>
          {errors.email && touched.email && (
            <ErrorField>{errors.email}</ErrorField>)}
          <InputFieldStyled
            id="email"
            name="email"
          />
          <FieldName>Prize Type</FieldName>
          {errors.prizeType && touched.prizeType && (
            <ErrorField>{errors.prizeType}</ErrorField>)}
          <InputFieldStyled id="prizeType" name="prizeType" component="select" style={{ fontFamily: 'CryUncial', fontSize: '16px' }}>
            {!values.prizeType && (
              <option key="empty" value="">Please select an option</option>
            )}
            <option value="Indigo">Indigo</option>
            <option value={'Tim Horton\'s'}>Tim Horton&apos;s</option>
            <option value="Amazon">Amazon</option>
          </InputFieldStyled>
          {(values.prizeType && values.email && (
            <ConfirmText>
              We will send a $10
              {' '}
              {values.prizeType}
              {' '}
              gift card to
              {' '}
              {values.email}
              {' '}
              and Portl will donate $10 to Kid&apos;s Help Phone as well!
            </ConfirmText>
          ))}
          <ButtonContainer type="submit">Claim Prize</ButtonContainer>
        </Form>
      )}
    </Formik>
  </>
);

const SubmittedComponent = () => (
  <>
    <DescriptionSubmitted>
      Thanks for playing
      {' '}
      <span>Some Bunny That You Used To Know!</span>
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      You&apos;ll receive your gift card via email soon :)
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      Happy Easter!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      - The Portl Team
    </DescriptionSubmitted>
  </>
);

const RedeemedComponent = () => (
  <>
    <DescriptionSubmitted>Oops...</DescriptionSubmitted>
    <DescriptionSubmitted>
      Looks like the Easter was already saved by you!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      Only one prize can be redeemed per person. But thanks for playing!
    </DescriptionSubmitted>
  </>
);

const EasterEggGameWin = () => (
  <GameSubmitWin
    appName="EasterEggGame"
    BackgroundTemplate={BackgroundTemplate}
    FormComponent={FormComponent}
    SubmittedComponent={SubmittedComponent}
    RedeemedComponent={RedeemedComponent}
  />
);

export default EasterEggGameWin;
