import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Utils
import { confirmEvent, postEventForm } from '../../utils/api';
import { getCookie, returnDateOneYearFromNow } from '../../utils/helpers';

const GameSubmitWin = ({
  appName,
  FormComponent,
  SubmittedComponent,
  RedeemedComponent,
  BackgroundTemplate,
}) => {
  const { IMEI, code } = useParams();
  const [status, setStatus] = useState(getCookie(`${appName}winner`) ? 'redeemed' : '');

  useEffect(() => {
    let isMounted = true;

    const confirmWinner = async () => {
      const response = await confirmEvent(appName, code, IMEI);
      console.log(response);
      if (response.error && isMounted) {
        return setStatus('redeemed');
      }

      if (isMounted) setStatus('form');
      return null;
    };

    confirmWinner();

    return () => { isMounted = false; };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values) => {
    postEventForm(appName, { // handle errors??
      ...values,
      code,
      IMEI,
    });

    // set cookie so they cannot enter again
    document.cookie = `${appName}winner=true; expires=${returnDateOneYearFromNow().toUTCString()}; path=/ ;secure";`;
    setStatus('submitted');
  };

  const renderSwitch = () => {
    switch (status) {
      case 'form': return <FormComponent onSubmit={handleSubmit} />;

      case 'submitted': return <SubmittedComponent />;

      case 'redeemed': return <RedeemedComponent />;

      default: return <FormComponent onSubmit={handleSubmit} />;
    }
  };

  return (
    <BackgroundTemplate>
      {renderSwitch()}
    </BackgroundTemplate>
  );
};

export default GameSubmitWin;
