/* eslint-disable import/prefer-default-export */
import io from 'socket.io-client';
import { serverUrl } from '../config';

let socket;

export const initConnection = () => {
  if (socket) return socket;

  const wsServer = serverUrl.indexOf('localhost') >= 0 ? 'http://localhost:5000/riders' : `${serverUrl}:5000/riders`;
  socket = io(wsServer, { forceNew: true, transports: ['websocket'] });
  return socket;
};
