import styled from 'styled-components';
import { Field } from 'formik';

const InputField = styled(Field)`
    width: 100%;
    height: 37px;
    border-radius: 10px;
    border: solid 1px #d6d6d6;
    font-size: 16px;
    text-align: left;
    padding: 0 20px;
`;

export default InputField;
