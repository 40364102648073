/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const RotateDeviceOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    padding: 0 20px;
    background-color: #272a2c;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
    display: ${({ open }) => (open ? 'block' : 'none')};
`;
const RotateDeviceTitle = styled.div`
    padding: 60px 30px 68px 30px;
    text-align: center;
    font-size: 36px;
    font-family: Montserrat;
    color: #ffffff;
`;
const RotateDeviceText = styled(RotateDeviceTitle)`
    padding: 0 20px;
    font-size: 24px;
    line-height: 1.21;
    padding-top: 68px;
`;
const StyledSVG = styled.svg`
    display: block;
    margin: auto;
`;

const RotateSVG = () => (
  <StyledSVG xmlns="http://www.w3.org/2000/svg" width="100.392" height="100.392" viewBox="0 0 100.392 100.392">
    <path fill="none" d="M0 0h100.392v100.392H0z" />
    <path fill="#1fb6ff" d="M68.776 10.541a43.927 43.927 0 0 1 24.973 35.472h6.274A50.171 50.171 0 0 0 50.037 0l-2.761.125 15.937 15.938zM42.633 7.32a6.236 6.236 0 0 0-8.868 0l-26.6 26.6a6.236 6.236 0 0 0 0 8.868l50.276 50.283a6.236 6.236 0 0 0 8.868 0l26.6-26.6a6.236 6.236 0 0 0 0-8.868zm19.241 81.317L11.6 38.358l26.6-26.6 50.278 50.276zM31.3 89.85A43.867 43.867 0 0 1 6.324 54.379H.05a50.171 50.171 0 0 0 49.987 46.013l2.761-.125L36.86 84.329 31.3 89.85z" transform="translate(.159)" />
  </StyledSVG>
);

export const useRotateDeviceOverlay = () => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const setOrientationStatus = () => {
      const isValuePortrait = window.screen.orientation.type.includes('portrait');
      setIsPortrait(isValuePortrait);
    };
    setOrientationStatus();

    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', setOrientationStatus);
    }
    return () => window.removeEventListener('orientationchange', setOrientationStatus);
  }, []);

  const RotateDevice = ({ open }) => (
    <RotateDeviceOverlay open={open}>
      <RotateDeviceTitle>Rotate device to continue</RotateDeviceTitle>
      <RotateSVG />
      <RotateDeviceText>
        This game must be played in
        {' '}
        <b>landscape</b>
        {' '}
        orientation.
        <br />
        <br />
        Please rotate your device to continue.
      </RotateDeviceText>
    </RotateDeviceOverlay>
  );

  return <RotateDevice open={isPortrait} />;
};
