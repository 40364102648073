const serverUrl = process.env.REACT_APP_SERVER_URL;
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

console.log('env var:', process.env.REACT_APP_ENV);
const isProduction = process.env.REACT_APP_ENV === 'production';
export {
  serverUrl,
  strapiUrl,
  isProduction,
};
