import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// utils
import { initConnection } from '../../utils/socket';

const QuizBackground = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url('${({ src }) => src}');
    background-size: cover;
    position: relative;
    ${({ font }) => font && `font-family: ${font}`};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100%;
`;
const AnswersContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 55px 0px 0px 0px;
`;
const Grid = styled.div`
    display: inline-grid;
    justify-items: start;
    grid-gap: 50px;
    margin-left: 25px;
    margin-right: 25px;
`;

const ScoreBoard = ({
  info, setInfo, onSubmit, view: View, submitScoreLoading,
}) => {
  const [selectedContent, setSelectContent] = useState();

  const handleChange = ({ target }) => {
    setInfo((prev) => ({ ...prev, name: target.value.slice(0, target.maxLength) }));
  };

  const handleSelect = (elemId) => {
    setSelectContent(elemId);
    setInfo((prev) => ({ ...prev, content: elemId }));
  };

  return (
    <View
      score={info.score}
      name={info.name}
      handleNameChange={handleChange}
      handleSelect={handleSelect}
      selectedContent={selectedContent}
      onSubmit={onSubmit}
      correctlyAnswered={info.correct}
      submitScoreLoading={submitScoreLoading}
    />
  );
};

const Quiz = ({
  privateCode,
  tabletIMEI,
  background,
  Start,
  QuestionTitle,
  QuestionTips,
  OptComponent,
  ScorePage,
  LeaderboardPage,
}) => {
  const [status, setStatus] = useState();
  const [isSocketConnect, setSocketConnection] = useState(false);
  const [question, setQuestion] = useState(0);
  const [total, setTotal] = useState(0);
  const [info, setInfo] = useState({
    name: '', score: 0, content: '', correct: 0,
  });
  const [ranks, setRanks] = useState({ top10: [], position: 0 });
  const [disableClick, setDisableClick] = useState(false);
  const [submitScoreLoading, setSubmitScoreLoading] = useState(false);

  const socketRef = useRef(initConnection());
  const query = window.location.pathname.split('/');
  const code = privateCode || query.pop();
  const IMEI = tabletIMEI || query.pop();

  const toggleScoreLoading = () => {
    setSubmitScoreLoading((prevState) => !prevState);
  };

  const playAgain = () => {
    setInfo({
      name: '', score: 0, content: '', correct: 0,
    });
    setStatus('');
    setRanks({ top10: [], position: 0 });
    setDisableClick(false);
    setQuestion(0);
  };

  useEffect(() => {
    const socket = socketRef.current;
    return () => socket.emit('desactiveGameListeners');
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on('tabletConnected', () => {
      setSocketConnection(true);
      console.log(isSocketConnect);
    });
    socket.on('questionNumber', (number) => {
      setQuestion(number);
      setDisableClick(false);
    });
    socket.on('scoreInfo', (tabletInfo) => {
      setInfo({ ...tabletInfo });
      setStatus('score');
    });
    socket.on('leaderboard', ({ top10, position }) => {
      setDisableClick(false);
      toggleScoreLoading();
      setRanks({ top10, position });
      setStatus('rank');
    });
    socket.on('gameRestarted', () => {
      playAgain();
    });
    socket.on('notConnected', (msg) => {
      setSocketConnection(false);
      console.log(msg);
    });

    socket.emit('joinGame', { code, IMEI });

    socket.on('disconnect', () => {
      setSocketConnection(false);
    });

    return () => {
      socket.off('tabletConnected');
      socket.off('questionNumber');
      socket.off('notConnected');
      socket.off('quizStarted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStart = () => {
    socketRef.current.on('quizStarted', (totalQuestions) => {
      setTotal(totalQuestions);
      setStatus('question');
    });
    socketRef.current.emit('startQuiz');
  };

  const handleOption = (opt) => {
    if (disableClick) return;

    setDisableClick(true);
    socketRef.current.emit('answerQuestion', { question, opt });
  };

  const handleSubmission = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleScoreLoading();
    socketRef.current.emit('persistScore', info);
  };

  const handlePlayAgain = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (disableClick) return;

    setDisableClick(true);
    socketRef.current.emit('playAgain');
  };

  switch (status) {
    case 'question':
      return (
        <QuizBackground src={background}>
          <QuestionTitle>
            Question
            {' '}
            {question + 1}
            /
            {total}
          </QuestionTitle>
          <QuestionTips>
            Refer to the tablet for the question and then select your answer below.
          </QuestionTips>
          <AnswersContainer>
            <Grid>
              <OptComponent onClick={() => handleOption(0)} index={0} />
              <OptComponent onClick={() => handleOption(2)} index={2} />
            </Grid>
            <Grid>
              <OptComponent onClick={() => handleOption(1)} index={1} />
              <OptComponent onClick={() => handleOption(3)} index={3} />
            </Grid>
          </AnswersContainer>
        </QuizBackground>
      );
    case 'score':
      return (
        <ScoreBoard
          info={info}
          setInfo={setInfo}
          onSubmit={handleSubmission}
          view={ScorePage}
          submitScoreLoading={submitScoreLoading}
        />
      );
    case 'rank':
      return (
        <LeaderboardPage
          sortedTop10={ranks.top10}
          position={ranks.position}
          score={info.score}
          handlePlayAgain={handlePlayAgain}
        />
      );
    default:
      return <Start handleClick={handleStart} />;
  }
};

export default Quiz;
