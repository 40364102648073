import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
    background-image: url('/assets/hourglassIcon.png');
    background-size: cover;
    width: 35.4px;
    height: 44.2px;
    margin: 0 auto 16px;
`;

const InfoWrapper = styled.div`
    max-width: 342px;
    padding: 18px 20px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #006abb;
    margin: 23px auto 0;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
`;

const Space = styled.div`
    height: 10px;
`;

const InfoTimeout = () => (
  <InfoWrapper>
    <IconWrapper />
    Your Portl session has timed out.
    <Space />
    Please rescan the QR code on the tablet home screen to continue.
  </InfoWrapper>
);

export default InfoTimeout;
