import axios from 'axios';
import { serverUrl, strapiUrl } from '../config';

const postEventData = async (eventName, values) => {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const { data } = await axios.post(
      `${serverUrl}/events/${eventName}`,
      formData,
    );

    return data;
  } catch (error) {
    console.log('Error: ', error);
    return { error };
  }
};

const resetTabletTimer = async (IMEI) => {
  try {
    const { data } = await axios(
      `${serverUrl}/rider/started/${IMEI}`,
    );

    return data;
  } catch (error) {
    console.log('Error: ', error);
    return { error };
  }
};

const nextTabletLoop = async (IMEI) => {
  try {
    const { data } = await axios(
      `${serverUrl}/rider/ended/${IMEI}`,
    );

    return data;
  } catch (error) {
    console.log('Error: ', error);
    return { error };
  }
};

const getTiles = async () => {
  try {
    const { data } = await axios(`${strapiUrl}/welcometiles`);

    return data;
  } catch (error) {
    return { error };
  }
};

const confirmEvent = async (eventName, code, IMEI) => {
  try {
    const { data } = await axios(`${serverUrl}/rider/authenticate/${eventName}/${code}/${IMEI}`);
    return data;
  } catch (error) {
    return { error };
  }
};

const postEventForm = async (eventName, values) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/rider/form/${eventName}`,
      values,
    );

    return data;
  } catch (error) {
    console.log('Error: ', error);
    return { error };
  }
};

export {
  postEventData,
  resetTabletTimer,
  nextTabletLoop,
  getTiles,
  confirmEvent,
  postEventForm,
};
