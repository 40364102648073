import React from 'react';
import styled from 'styled-components';

// components
import SocialMediaIcons from '../../components/SocialMediaIcons';
import PortlCopyRight from '../../components/PortlCopyRight';

const Background = styled.div`
    width: 100vw;
    padding: 25px 0 20px;
    ${({ color }) => (color
    ? `background-color: ${color};`
    : 'background-image: linear-gradient(102deg, #51c6ff 0%, #1fb6ff);')}
`;

const PortlCopyRightStyled = styled(PortlCopyRight)`
    margin-top: 15px;
    text-align: center;
`;

const Footer = ({ color }) => (
  <Background color={color}>
    <SocialMediaIcons />
    <PortlCopyRightStyled />
  </Background>
);

export default Footer;
