import React from 'react';
import styled from 'styled-components';
import ordinal from 'ordinal';

const Container = styled.div`
    margin-bottom: 25px;
`;

const TableItem = styled.div`
    display: grid;
    grid-template-columns: 42px 94px 106px;
    text-align: right;
    margin: 0 auto;
    width: fit-content;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #1f2639;
    margin-top: 10px;


    ${({ highlight }) => highlight && `
        color: #c40000;
        font-weight: 900;
    `}
`;

const TableTitle = styled(TableItem)`
    margin-top: 15px;
    font-weight: bold;
`;

const QuizRankTemplate = ({ ranks }) => (
  <Container>
    <TableTitle>
      <div>Rank</div>
      <div>Name</div>
      <div>Score</div>
    </TableTitle>
    {ranks.top10.map((element, i) => (
      <TableItem key={element.score + element.name} highlight={i === ranks.position}>
        <div>{ordinal(i + 1)}</div>
        <div>{element.name}</div>
        <div>{element.score}</div>
      </TableItem>
    ))}
  </Container>
);

export default QuizRankTemplate;
