import React from 'react';
import styled from 'styled-components';
import ordinal from 'ordinal';
import ClipLoader from 'react-spinners/ClipLoader';
import Quiz from '../Quiz';
import QuizNameInput from '../../components/QuizNameInput';
import SubmitLoadingContainer from '../../components/SubmitLoadingContainer';

const THEMECOLOR = '#cb2730';

const QuizBackground = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url('${({ src }) => src}');
    background-size: cover;
    position: relative;
    ${({ font }) => font && `font-family: ${font}`};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100%;
    padding: 35px;
`;
const QuestionTitle = styled.div`
    font-family: Limelight;
    font-size: 40px;
    color: #cb2730;
    margin-top: 56px;
    margin-bottom: 28px;
`;
const QuestionTips = styled.div`
    font-family: Playfair;
    line-height: 1.6;
    font-size: 21px;
    color: #e5d2be;
    padding: 0 30px;
`;
const OptionsLetter = styled.div`
    font-family: PlayfairDisplay;
    display: inline-block;
    text-align: center;
    width: 118px;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${THEMECOLOR};
    font-size: 68px;
    font-weight: bold;
    line-height: 118px;
    color: #e5d2be;
`;
const Header = styled.h1`
    font-family: Limelight;
    font-size: 40px;
    color: ${THEMECOLOR};
    margin-top: 20px;
`;
const SubHeader = styled.p`
    font-family: Limelight;
    color: ${THEMECOLOR};
    font-size: 21px;
    display: inline-block;
    padding-top: 5px;
`;
const ResultsText = styled.p`
    color: #e5d2be;
    font-family: PlayfairDisplay;
    font-size: 21px;
    padding-top: 30px;
`;
const SelectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
`;
const ContentSelection = styled.button`
    height: 45px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    background-color: ${({ selected }) => (selected ? '#ff8b91' : '#e5d2be')};
    text-align: center;
    line-height: 45px;
    color: #222222;
    font-family: PlayfairDisplay;
    font-size: 16px;
    &:focus {
        outline: none;
    }
`;

const SubmitScoreButton = styled.button`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: ${THEMECOLOR};
    border: none;
    color: #e5d2be;
    font-family: Limelight;
    font-size: 21px;
    line-height: 45px;
    margin-top: 30px;

    &:focus {
        outline: none;
    }
`;
const ScoreHeader = styled.h3`
    text-transform: uppercase;
    font-family: PlayfairDisplay;
    font-size: 21px;
    color: #ffffff;
    margin: 28px 0px;
`;
const TableItem = styled.div`
    position: relative;
    line-height: 42px;
    font-family: PlayfairDisplay;
    font-size: 21px;
    width: 100%;
    height: 42px;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
    color: white;
    ${({ highlight }) => highlight && (`
        background-color: ${THEMECOLOR};
        border-radius: 5px;
    `)}
`;
const StarSvg = styled.svg`
    position: absolute;
    top: 10px;
    ${(props) => (props.left ? 'left: 10px;' : 'right: 10px;')}
`;
const TableItemSpacer = styled.span`
    display: inline-block;
    width: 120px;
`;
const PlayAgainButton = styled.button`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: none;
    background-color: ${THEMECOLOR};
    color: #e5d2be;
    font-size: 21px;
    line-height: 45px;
    font-family: Limelight;
    margin-top: 30px;

    &:focus {
        outline: none;
    }
`;
const StarIcon = ({ left }) => (
  <StarSvg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 24 24" width={20} left={left}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="#e5d2be" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </StarSvg>
);

const LeaderboardPage = ({
  position, score, sortedTop10, handlePlayAgain,
}) => (
  <QuizBackground src="/assets/movie-quiz-mobile-bg.webp">
    <Header>
      You rank
      {' '}
      {ordinal(position + 1)}
      !
    </Header>
    <SubHeader>
      Score:
      {' '}
      {score}
    </SubHeader>
    <ScoreHeader>In order of score</ScoreHeader>
    {sortedTop10.map(({ score: top10Score, name }, i) => (
      <TableItem key={top10Score + name} highlight={i === position}>
        {i === position && <StarIcon left />}
        {name}
        <TableItemSpacer />
        {top10Score}
        {i === position && <StarIcon />}
      </TableItem>
    ))}
    <PlayAgainButton onClick={handlePlayAgain}>Play Again!</PlayAgainButton>
  </QuizBackground>
);

const ResultsPage = ({
  score,
  correctlyAnswered,
  name,
  handleNameChange,
  handleSelect,
  selectedContent,
  onSubmit,
  submitScoreLoading,
}) => (
  <QuizBackground src="/assets/movie-quiz-mobile-bg.webp">
    <Header>
      Results
    </Header>
    <SubHeader>
      Correctly Answered:
      {' '}
      {correctlyAnswered}
    </SubHeader>
    <SubHeader>
      Score:
      {' '}
      {score}
    </SubHeader>
    <ResultsText>Please enter your initials:</ResultsText>
    <QuizNameInput name={name} handleNameChange={handleNameChange} color="#e5d2be" />
    <SelectionContainer>
      <ContentSelection onClick={() => handleSelect('Sports')} selected={selectedContent === 'Sports'}>Sports</ContentSelection>
      <ContentSelection onClick={() => handleSelect('Rider generated content')} selected={selectedContent === 'Rider generated content'}>Rider generated content</ContentSelection>
      <ContentSelection onClick={() => handleSelect('Games')} selected={selectedContent === 'Games'}>Games</ContentSelection>
      <ContentSelection onClick={() => handleSelect('Giveaways and promos')} selected={selectedContent === 'Giveaways and promos'}>Giveaways and promos</ContentSelection>
      <ContentSelection onClick={() => handleSelect('News')} selected={selectedContent === 'News'}>News</ContentSelection>
    </SelectionContainer>
    {(submitScoreLoading)
      ? (
        <SubmitLoadingContainer>
          <ClipLoader loading={submitScoreLoading} color={THEMECOLOR} size={35} />
        </SubmitLoadingContainer>
      )
      : <SubmitScoreButton onClick={onSubmit}>See Your Ranking!</SubmitScoreButton>}
  </QuizBackground>
);

const mapNumToLetter = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
};

const OptComponent = ({ index, onClick }) => (
  <OptionsLetter onClick={onClick}>{mapNumToLetter[index]}</OptionsLetter>
);

const Start = ({ handleClick }) => (
  <QuizBackground src="/assets/movie-quiz-start.webp" onClick={handleClick} />
);

const MovieQuiz = ({ privateCode, tabletIMEI }) => (
  <Quiz
    privateCode={privateCode}
    tabletIMEI={tabletIMEI}
    Start={Start}
    background="/assets/movie-quiz-mobile-bg.webp"
    QuestionTitle={QuestionTitle}
    QuestionTips={QuestionTips}
    OptComponent={OptComponent}
    ScorePage={ResultsPage}
    LeaderboardPage={LeaderboardPage}
  />
);

export default MovieQuiz;
