import styled from 'styled-components';

const PortlLogo = styled.div`
    background-image: url('/assets/logo.png');
    background-size: cover;
    width: 95px;
    height: 40px;
`;

export default PortlLogo;
