import React from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Components
import FormsContainer from '../../components/FormsContainer';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

// Compositions
import Footer from '../../compositions/Footer';
import GameSubmitWin from '../../compositions/GameSubmitWin';

const Background = styled.div`
    font-family: CryUncial;
    background: url('/assets/fourleafbackground.webp')center top no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Description = styled.div`
    font-family: FuturaPT-Bold;
    font-size: 18px;
    text-shadow: 0 6px 4px rgba(0, 0, 0, 0.72);
    color: #f1f8ff;
    padding-bottom: 15px;
`;
const DescriptionSubmitted = styled(Description)`
    font-size: 22px;
    line-height: 30px;
`;
const FieldName = styled.div`
    font-family: FuturaPT-Bold;
    font-size: 15px;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.72);
    color: #f1f8ff;
`;
const ErrorField = styled.div`
    font-family: Montserrat;
    color: #d80f4d;
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
`;
const InputFieldStyled = styled(InputField)`
    margin-top: 8px;
    margin-bottom: 18px;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
`;
const InputTextFieldStyled = styled(InputFieldStyled)`
    height: 148px;
    padding-top: 6px;
    padding-bottom: 6px;
`;
const ButtonContainer = styled(Button)`
    font-family: CryUncial;
    text-transform: uppercase;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.63);
    border: solid 4px #0b0f33;
    border-radius: 10px;
    height: 50px;
    background-color: #2b7c20;
    font-size: 26px;
    letter-spacing: 2px;
    margin-top: 12px;
    margin-bottom: 17px;
`;
const StyledLabel = styled.label`
    display: flex;
    flex-direction: row;
    padding-bottom: 18px;
`;
const StyledFormsContainer = styled(FormsContainer)`
    padding-top: 160px;
`;
const Checkbox = styled(Field)`
    margin-top: 5px;
`;
const StyledCheckBoxText = styled(FieldName)`
    padding-left: 5px;
`;
const SubmitContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 40px;
`;

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
const nameRegExp = /^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/;
const postalCodeRegEx = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegExp, 'Please enter a valid name.')
    .required('Please enter a valid name.'),
  lastName: Yup.string()
    .matches(nameRegExp, 'Please enter a valid name.')
    .required('Please enter a valid name.'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Please enter a valid phone number.')
    .required('Please enter a valid phone number.'),
  address1: Yup.string()
    .required('Please enter a valid address.'),
  city: Yup.string()
    .required('Please enter a valid City.'),
  postalCode: Yup.string()
    .trim()
    .matches(postalCodeRegEx, 'Please enter a valid Canadian postal code.')
    .required('Postal Code is required.'),
  prizeType: Yup.string()
    .required('Please select a prize type'),
  over19: Yup.boolean()
    .when('prizeType', {
      is: 'alcoholic',
      then: Yup.boolean()
        .oneOf([true], 'You must be of legal drinking age within your Province to claim this prize.'),
    }),
});

const BackgroundTemplate = ({ children }) => (
  <Background>
    <StyledFormsContainer>
      {children}
    </StyledFormsContainer>
    <Footer color="none" />
  </Background>
);

const FormComponent = ({ onSubmit }) => (
  <>
    <Description>
      Congratulations on winning Four Leaf Fortune!
    </Description>
    <Description>
      Choose between an alocholic or non-alcoholic prize.
      Our Leprechaun will begrudgingly deliver your prize!
    </Description>
    <Description>
      Where do you want your prize sent?
    </Description>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        postalCode: '',
        prizeType: '',
        over19: false,
        specialInstruction: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values }) => (
        <Form>
          <FieldName>First Name</FieldName>
          {errors.firstName && touched.firstName && (
            <ErrorField>{errors.firstName}</ErrorField>)}
          <InputFieldStyled id="firstName" name="firstName" />
          <FieldName>Last Name</FieldName>
          {errors.lastName && touched.lastName && (
            <ErrorField>{errors.lastName}</ErrorField>)}
          <InputFieldStyled
            id="lastName"
            name="lastName"
          />
          <FieldName>Phone Number</FieldName>
          {errors.phone && touched.phone && (
            <ErrorField>{errors.phone}</ErrorField>)}
          <InputFieldStyled
            id="phone"
            name="phone"
          />
          <FieldName>Address Line 1</FieldName>
          {errors.address1 && touched.address1 && (
            <ErrorField>{errors.address1}</ErrorField>)}
          <InputFieldStyled
            id="address1"
            name="address1"
          />
          <FieldName>Address Line 2</FieldName>
          <InputFieldStyled
            id="address2"
            name="address2"
            placeholder="(Optional)"
          />
          <FieldName>City</FieldName>
          {errors.city && touched.city && (
            <ErrorField>{errors.city}</ErrorField>)}
          <InputFieldStyled
            id="city"
            name="city"
          />
          <FieldName>Province</FieldName>
          {errors.province && touched.province && (
            <ErrorField>{errors.province}</ErrorField>)}
          <InputFieldStyled
            id="province"
            name="province"
          />
          <FieldName>Postal Code</FieldName>
          {errors.postalCode && touched.postalCode && (
            <ErrorField>{errors.postalCode}</ErrorField>)}
          <InputFieldStyled
            id="postalCode"
            name="postalCode"
          />
          <FieldName>Prize Type</FieldName>
          {errors.prizeType && touched.prizeType && (
            <ErrorField>{errors.prizeType}</ErrorField>)}
          <InputFieldStyled id="prizeType" name="prizeType" component="select" style={{ fontFamily: 'CryUncial', fontSize: '16px' }}>
            {!values.prizeType && (
              <option key="empty" value="">Please select an option</option>
            )}
            <option value="non-alcoholic">Non-Alcholic</option>
            <option value="alcoholic">Alcoholic</option>
          </InputFieldStyled>
          {values.prizeType === 'alcoholic'
            && (
              <>
                {errors.over19 && touched.over19 && (
                  <ErrorField>{errors.over19}</ErrorField>)}
                <StyledLabel>
                  <Checkbox type="checkbox" name="over19" />
                  <StyledCheckBoxText>
                    I certify I am of legal drinking age in my province.
                  </StyledCheckBoxText>
                </StyledLabel>
              </>
            )}
          <FieldName>Special Delivery instructions?</FieldName>
          <InputTextFieldStyled
            id="specialInstruction"
            name="specialInstruction"
            as="textarea"
            placeholder="(Optional)"
          />
          <ButtonContainer type="submit">Submit</ButtonContainer>
        </Form>
      )}
    </Formik>
  </>
);

const SubmittedComponent = () => (
  <SubmitContainer>
    <DescriptionSubmitted>
      And you&apos;re done!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      Your prize is on it&apos;s way!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      We hope you enjoyed the experience on our tablets.
      If you were happy with your experience, consider giving Portl a shout out on social media!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      Happy St. Patrick&apos;s Day!
    </DescriptionSubmitted>
    <DescriptionSubmitted>
      - The Portl Team
    </DescriptionSubmitted>
  </SubmitContainer>
);

const RedeemedComponent = () => (
  <SubmitContainer>
    <DescriptionSubmitted>Oops...</DescriptionSubmitted>
    <Description>
      Looks like the luck of the Irish is already with you!
      Why not share the luck with other people.
    </Description>
    <Description>
      Only one prize can be redeemed per person. But thanks for playing!
    </Description>
  </SubmitContainer>
);

const FourLeafFortuneWin = () => (
  <GameSubmitWin
    appName="FourLeafFortune"
    BackgroundTemplate={BackgroundTemplate}
    FormComponent={FormComponent}
    SubmittedComponent={SubmittedComponent}
    RedeemedComponent={RedeemedComponent}
  />
);

export default FourLeafFortuneWin;
