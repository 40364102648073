import styled from 'styled-components';

const Button = styled.button`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-family: Montserrat;
    font-weight: 600;
    color: #ffffff;
    border: none;
    background-color: ${({ color }) => color || '#169aff'};

    &:focus {
        outline: none;
    }
`;

export default Button;
