import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FlipGame from '../FlipGame';

const Background = styled.div`
  position: relative;
  background: url('/assets/easter/easterBackground.webp') center center;
  background-size: cover;
  width: 100vw;
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;
const WinBackground = styled(Background)`
  align-items: flex-start;
`;
const StartText = styled.div`
  max-width: 450px;
  padding-top: 50px;
  padding-bottom: 28px;
  font-size: 30px;
  line-height: 1.23;
  text-align: center;
  color: #ffffff;
`;
const StartButton = styled.button`
  width: 250px;
  height: 65px;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.63);
  border-radius: 10px;
  border: solid 4px #f9f9f9;
  background-color: #2b7c20;

  &:focus {
      outline: none;
  }
`;
const StartButtonText = styled.div`
  font-size: 32px;
  font-family: 'Dela Gothic One', cursive;
  color: #ffffff;
  text-transform: uppercase;
`;
const CongratsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 25px;
  padding-left: 58px;
  width: 540px;

  @media (min-width: 699px) {
      padding-top: 40px;
  }
`;
const CongratsHeader = styled.div`
  font-family: CryUncial;
  font-size: 30px;
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.64);
  text-align: left;
  color: #ffffff;
`;
const CongratsText = styled.div`
  font-family: FuturePT-Book;
  font-size: 18px;
  text-align:left;
  text-shadow: 0 8px 6px rgba(0, 0, 0, 0.72);
  line-height: 18px;
  color: #f1f8ff;
  max-width: 400px;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 720px) {
      max-width: 580px;
  }
`;
const ClaimButton = styled(StartButton)`
  width: 190px;
  height: 50px;
  font-size: 22px;
  color: #ffffff;
`;
const PlayAgainButton = styled(ClaimButton)`
  margin: 18px auto 0;
`;
const GameContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GameGrid = styled.div`
  position: relative;
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(5, 1fr);
  min-width: 360px;
  margin: 0 auto;
  padding-top: 25px;
`;
const EmptyDiv = styled.div`
  height: 80px;
  width: 80px;
`;
const Coaster = styled.img`
  height: 88px;
  width: 88px;
`;
const Image = styled.div`
  background: url('${({ src }) => src}') no-repeat center;
  background-size: contain;
  width: 265px;
  height: 258px;
  position: absolute;
  top: 60px;
  right: 40px;
`;

const StartComponent = ({ handleClick }) => (
  <Background>
    <StartText>
      The rules are displayed on the tablet.
      Press
      {' '}
      <b>start</b>
      {' '}
      when you are ready to play!
    </StartText>
    <StartButton onClick={handleClick}>
      <StartButtonText>start</StartButtonText>
    </StartButton>
  </Background>
);

const WinComponent = ({ IMEI, code }) => {
  const history = useHistory();
  return (
    <WinBackground>
      <CongratsContainer>
        <CongratsHeader>Congratulations!</CongratsHeader>
        <CongratsText>
          You saved the Easter Bunny&apos;s reputation!
          <br />
          <br />
          Click below to claim your prize, and Happy Easter!
        </CongratsText>
        <ClaimButton onClick={() => history.push(`/EasterEggGameWin/${IMEI}/${code}`)}>Claim Prize</ClaimButton>
      </CongratsContainer>
      <Image src="/assets/easter/threeBasket.webp" />
    </WinBackground>
  );
};

const Tile = ({ show, onHiddenTileClick }) => {
  if (!show) {
    return (
      <Coaster
        src="/assets/easter/basket.webp"
        onClick={onHiddenTileClick}
      />
    );
  }
  return <EmptyDiv />;
};

const OverText = styled.div`
  width: fit-content;
  margin: 0 auto;
  -webkit-text-stroke: 2px #dd65ba;
  font-family: 'Dela Gothic One', cursive;
  font-size: 32px;
`;
const LooseText = styled(CongratsText)`
  margin: 0 auto;
`;

const LoseScreen = ({ handlePlayAgain }) => (
  <WinBackground>
    <CongratsContainer>
      <OverText>GAME OVER</OverText>
      <LooseText>
        It looks like this Easter will be bunny!
      </LooseText>
      <PlayAgainButton onClick={handlePlayAgain}>Play Again</PlayAgainButton>
    </CongratsContainer>
    <Image src="/assets/easter/bunny.webp" />
  </WinBackground>
);

const GameComponent = ({
  tiles,
  handleTileClick,
  disableClick,
}) => (
  <Background disableClick={disableClick}>
    <GameContainer>
      <GameGrid>
        {tiles && tiles.map((row, rowIndex) => row.map((tile, columnIndex) => {
          const key = tile.value + rowIndex + columnIndex;
          return (
            <Tile
              key={key}
              onHiddenTileClick={() => handleTileClick(tile, rowIndex, columnIndex)}
              value={tile.value}
              show={tile.show}
            />
          );
        }))}
      </GameGrid>
    </GameContainer>
  </Background>
);

const EasterEggGame = ({ privateCode, tabletIMEI }) => (
  <FlipGame
    privateCode={privateCode}
    tabletIMEI={tabletIMEI}
    StartComponent={StartComponent}
    WinComponent={WinComponent}
    GameComponent={GameComponent}
    winCondition={3}
    LoseComponent={LoseScreen}
  />
);

export default EasterEggGame;
