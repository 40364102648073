import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

// Components
import FormsContainer from '../../components/FormsContainer';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

// Compositions
import Footer from '../../compositions/Footer';

// Utils
import { confirmEvent, postEventForm } from '../../utils/api';
import { getCookie, returnDateOneDayFromNow } from '../../utils/helpers';

const Background = styled.div`
  font-family: 'Open Sans', sans-serif;
  background-image: linear-gradient(
      to bottom,
      rgba(39, 42, 44, 0) 0%,
      rgba(39, 42, 44, 1) 42%
    ),
    url('/assets/brickWall.webp');
  background-color: rgba(39, 42, 44, 1);
  background-size: 1217px;
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const Title = styled.img`
  // change to img tag
  max-width: 344px;
  height: 145px;
  // background-image: url('/assets/loveToWinLogo.png');
  // background-size: cover;
  margin-bottom: 35px;
`;

const Description = styled.div`
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 25px;
`;

const Question = styled(Description)`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
`;

const FieldName = styled.div`
  text-shadow: 0 0 10px #1fb6ff;
  -webkit-text-stroke: 0.5px #169aff;
  font-family: NeonTubes2;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 2px;
`;

const InputFieldStyled = styled(InputField)`
  margin-top: 8px;
  margin-bottom: 18px;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
`;

const InputTextFieldStyled = styled(InputFieldStyled)`
  height: 148px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ButtonContainer = styled(Button)`
  font-family: NeonTubes2;
  background-color: transparent;
  border: 2px solid #1fb6ff;
  border-radius: 10px;
  height: 50px;
  color: #ffffff;
  text-shadow: 0 3px 6px #dd65ba;
  -webkit-text-stroke: 0.5px #dd65ba;
  font-size: 26px;
  letter-spacing: 2px;
  margin-top: 12px;
  margin-bottom: 17px;
`;

const BackgroundTemplate = ({ children }) => (
  <Background>
    <FormsContainer>
      <Title src="/assets/loveToWinLogo.png" />
      {children}
    </FormsContainer>
    <Footer color="#272a2c" />
  </Background>
);

const FormInstruction = ({ onSubmit }) => (
  <>
    <Description>Congratulations on winning Love To Win!</Description>
    <Description>
      Send someone a bouquet of roses to brighten their day! The roses should arrive
      on Valentine&apos;s Day.
    </Description>
    <Description>Flowers can be sent to any place in Canada.</Description>
    <Question>Who would you like to send them to?</Question>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        postalCode: '',
        message: '',
        specialInstruction: '',
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <FieldName>First Name</FieldName>
        <InputFieldStyled id="firstName" name="firstName" />
        <FieldName>Last Name</FieldName>
        <InputFieldStyled id="lastName" name="lastName" />
        <FieldName>Phone Number</FieldName>
        <InputFieldStyled id="phone" name="phone" />
        <FieldName>Address Line 1</FieldName>
        <InputFieldStyled id="address1" name="address1" />
        <FieldName>Address Line 2</FieldName>
        <InputFieldStyled id="address2" name="address2" placeholder="(Optional)" />
        <FieldName>City</FieldName>
        <InputFieldStyled id="city" name="city" />
        <FieldName>Province</FieldName>
        <InputFieldStyled id="province" name="province" />
        <FieldName>Postal Code</FieldName>
        <InputFieldStyled id="postalCode" name="postalCode" />
        <FieldName>Message</FieldName>
        <InputTextFieldStyled
          id="message"
          name="message"
          as="textarea"
          placeholder="(Optional)"
        />
        <FieldName>Special Delivery instructions?</FieldName>
        <InputTextFieldStyled
          id="specialInstruction"
          name="specialInstruction"
          as="textarea"
          placeholder="(Optional)"
        />
        <ButtonContainer type="submit">Submit</ButtonContainer>
      </Form>
    </Formik>
  </>
);

const DescriptionSubmit = styled(Description)`
  font-size: 22px;
  width: 100%;
`;

const SuccessSubmition = () => (
  <>
    <DescriptionSubmit>And you’re done!</DescriptionSubmit>
    <DescriptionSubmit>
      Your special someone will receive a bouquet of roses on Valentine’s day.
    </DescriptionSubmit>
    <DescriptionSubmit>
      We hope you enjoyed the experience on our tablets. If you were happy with your
      experience, consider giving Portl a shout out on social media!
    </DescriptionSubmit>
    <DescriptionSubmit>Happy Valentine’s day!</DescriptionSubmit>
    <DescriptionSubmit>- The Portl Team</DescriptionSubmit>
  </>
);

// Game must be played from tablet, this component is only the Win form!
// If clicked from the remote control, privateCode and tabletIMEI will be set,
// the game should display a play only on tablet message and not fire confirm code fn
const LoveToWin = ({ tabletIMEI: startedFromRemoteControl }) => {
  const query = window.location.pathname.split('/');
  const code = query.pop();
  const IMEI = query.pop();

  const returnStartStatus = () => {
    if (startedFromRemoteControl) return 'tabletOnly';

    return getCookie('lovetowinwinner') ? 'redeemed' : '';
  };

  const [status, setStatus] = useState(returnStartStatus());

  useEffect(() => {
    let isMounted = true;

    const confirmWinner = async () => {
      const response = await confirmEvent('LoveToWin', code, IMEI);

      if (response.error && isMounted) {
        return setStatus('error');
      }

      if (isMounted) setStatus('form');
      return null;
    };

    if (!startedFromRemoteControl) confirmWinner();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values) => {
    postEventForm('LoveToWin', {
      // handle errors??
      ...values,
      code,
      IMEI,
    });

    // set cookie so they cannot enter again
    document.cookie = `lovetowinwinner=true; expires=${returnDateOneDayFromNow().toUTCString()}; path=/ ;secure";`;
    setStatus('submitted');
  };

  const renderSwitch = () => {
    switch (status) {
      case 'form':
        return <FormInstruction onSubmit={handleSubmit} />;

      case 'submitted':
        return <SuccessSubmition />;

      case 'redeemed':
        return (
          <>
            <DescriptionSubmit>Oops...</DescriptionSubmit>
            <Description>
              Looks like you&apos;ve already won some love! Why not share the love
              with other people.
            </Description>
            <Description>
              Only one prize can be redeemed per person. But thanks for playing!
            </Description>
          </>
        );
      case 'tabletOnly':
        return (
          <>
            <DescriptionSubmit>Tablet Only</DescriptionSubmit>
            <Description>
              This is a game that can only be played on the tablet! Continue to play
              on the tablet or head back to the main menu using the Home button.
            </Description>
          </>
        );
      default:
        return (
          <>
            <Description>
              Oops.... This is embarassing. Something went wrong on our end, we are
              working hard to fix it!
            </Description>
            <Description>Please go back to the menu and try again.</Description>
          </>
        );
    }
  };

  return <BackgroundTemplate>{renderSwitch()}</BackgroundTemplate>;
};

export default LoveToWin;
