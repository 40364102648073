import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// views
import Rescan from './views/Rescan';
import Quizzle from './views/Quizzle';
import NothingQuiz from './views/NothingQuiz';
import ChristmasQuiz from './views/ChristmasQuiz';
// Needs to adapt the voting systeam to the remote control
// import HalloweenVoting from './views/HalloweenVoting';
// import HalloweenContest from './views/HalloweenContest';
import TabletRemoteControl from './views/TabletRemoteControl';
import MovieQuiz from './views/MovieQuiz';
import LoveToWin from './views/LoveToWin';
import FourLeafFortuneWin from './views/FourLeafFortuneWin';
import FourLeafFortune from './views/FourLeafFortune';
import EasterEggGameWin from './views/EasterEggGameWin';
import EasterEggGame from './views/EasterEggGame';

const StyledToastContainer = styled(ToastContainer)`
  .toast-container {}
  .Toastify__toast {
    padding: 21px 17px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-bottom: 7px solid #2fd672;
    border-radius: 0 0 7px 7px;
    color: #222222;
    font-family: Montserrat;
    margin: 0;
  }
  .Toastify__close-button {
    color: #404040;
  }
  .Toastify__toast--error {}
  .Toastify__toast--warning {
    background-color: #ffa000;
  }
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;

const App = () => (
  <>
    <StyledToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
    />
    <Switch>
      <Route exact path="/" render={() => <Rescan />} />
      <Route path="/Quizzle/:IMEI/:code" render={() => <Quizzle />} />
      <Route path="/ChristmasQuiz/:IMEI/:code" render={() => <ChristmasQuiz />} />
      <Route path="/MovieQuiz/:IMEI/:code" render={() => <MovieQuiz />} />
      <Route path="/NothingQuiz/:IMEI/:code" render={() => <NothingQuiz />} />
      <Route path="/LoveToWin/:IMEI/:code" render={() => <LoveToWin />} />
      <Route path="/FourLeafFortuneWin/:IMEI/:code" render={() => <FourLeafFortuneWin />} />
      <Route path="/FourLeafFortune/:IMEI/:code" render={() => <FourLeafFortune />} />
      <Route path="/EasterEggGameWin/:IMEI/:code" render={() => <EasterEggGameWin />} />
      <Route path="/EasterEggGame/:IMEI/:code" render={() => <EasterEggGame />} />
      {/*
      <Route path="/HalloweenContest" render={() => <HalloweenContest />} />
      <Route path="/HalloweenVoting/:IMEI/:A/:B/:C/:D" render={() => <HalloweenVoting />} />
      */}
      <Route path="/tablet/control" render={() => <TabletRemoteControl />} />
      <Route render={() => <Rescan />} />
    </Switch>
  </>
);

export default App;
