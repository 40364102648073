import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ordinal from 'ordinal';
import ClipLoader from 'react-spinners/ClipLoader';
import SubmitLoadingContainer from '../../components/SubmitLoadingContainer';

// utils
import { initConnection } from '../../utils/socket';

// components
import SocialMediaIcons from '../../components/SocialMediaIcons';
import PortlCopyRight from '../../components/PortlCopyRight';

const Background = styled.div`
    background-color: #828fff;
    font-family: 'Press Start 2P', cursive;
    min-height: 100vh;
    padding-top: 6vh;
    position: relative;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-transform: uppercase;
    overflow: hidden;
    text-align: center;
`;

const Footer = styled.div`
    background-image: url('/assets/quizFloor.png');
    background-size: contain;
    background-repeat: repeat-x;
    margin-top: 38px;
    height: 50px;
`;

const Cloud = styled.img`
    position: absolute;
    width: 124px;
    top: 0;
    left: -54px;
`;

const Title = styled.div`
    width: 354.5px;
    height: 104.6px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #000000;
    background-image: url('/assets/quizTitleBck.png');
    background-size: cover;
    text-shadow: 0 5px 0 rgba(0, 0, 0, 0.86);
    font-size: 48px;
    line-height: 1.21;
    letter-spacing: -3.12px;
    color: #fedbca;
    margin: 0 auto;
    padding-top: 12px;
    position: relative;
    z-index: 1;
`;

const TitlePortl = styled.div`
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
    font-size: 15px;
    letter-spacing: normal;
    text-align: left;
    text-transform: lowercase;
    margin-left: 20px;
`;

const Tips = styled.div`
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.89);
    font-size: 16px;
    line-height: 1.56;
    margin: 0 auto;
    padding: 10vh 5vw 0;
`;

const StartBtn = styled.div`
    height: 124px;
    width: 265px;
    background-image: url('/assets/quizStartBtn.png');
    background-size: cover;
    margin: 10vh auto 0;
`;

const Start = ({ onStart, isSocketConnect }) => (
  <Background>
    <div>
      <Cloud src="/assets/quizCloud.png" />
      <Title>
        Quizzle
        <TitlePortl>by portl</TitlePortl>
      </Title>
      <Tips>Remember, faster answers will get you more points!</Tips>
      {isSocketConnect
        ? <StartBtn onClick={onStart} />
        : (
          <Tips>
            Please scan the QR code on the tablet
          </Tips>
        )}
    </div>
    <Footer />
  </Background>
);

const QuestionTitle = styled(Title)`
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.89);
    font-size: 30px;
    line-height: 0.83;
    text-align: center;
    padding: 27px;
    height: 84px;
`;

const QuestionTips = styled(Tips)`
    padding-top: 8vh;
    text-transform: initial;
`;

const OptContainer = styled.div`
    display: inline-block;
    margin: 48px auto 0;
`;

const Opt = styled.img`
    height: 101px;
    width: 121px;
    margin: 0 27px;
`;

const Question = ({ onOption, question, total }) => (
  <Background>
    <div>
      <QuestionTitle>
        Level
        {' '}
        {question + 1}
        /
        {total}
      </QuestionTitle>
      <QuestionTips>
        Refer to the tablet for the question and then select your answer below.
      </QuestionTips>
      <OptContainer>
        <Opt onClick={() => onOption(0)} src="/assets/quizOptA.png" />
        <Opt onClick={() => onOption(1)} src="/assets/quizOptB.png" />
      </OptContainer>
      <OptContainer>
        <Opt onClick={() => onOption(2)} src="/assets/quizOptC.png" />
        <Opt onClick={() => onOption(3)} src="/assets/quizOptD.png" />
      </OptContainer>
    </div>
    <Footer />
  </Background>
);

const PersonalScore = styled.div`
    font-size: 23px;
    text-align: center;
    margin: 40px auto 0;
`;

const Subtitle = styled.div`
    max-width: 340px;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.89);
    font-size: 16px;
    line-height: 1.56;
    text-align: center;
    margin: 40px auto 0;
`;

const InputContainer = styled.div`
    position: relative;
    width: fit-content;
    margin: 10px auto 0;
`;

const InputName = styled.input`
    letter-spacing: 10px;
    text-indent: 5px;
    background: transparent;
    width: 105px;
    border: none;
    font-size: 23px;
    color: #ffffff;
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
`;

const WhiteLine = styled.div`
    width: 27px;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    bottom: -4px;
    left: ${({ left }) => left}px;
`;

const ScoreOptContainer = styled.div`
    width: 280px;
    font-size: 16px;
    text-align: center;
    margin: 20px auto 0;
`;

const ContentOption = styled.div`
    position: relative;
    width: fit-content;
    line-height: 1.56;
    margin: 23px auto 0;
`;

const SelectArrow = styled.div`
    width: 18.6px;
    height: 22.6px;
    background-image: url('/assets/quizSelected.png');
    background-size: cover;
    position: absolute;
    left: -27px;
`;

const HighScoreBtn = styled.div`
    width: 284px;
    height: 89px;
    background-image: url('/assets/quizScore.png');
    background-size: cover;
    margin: 50px auto 0;
`;

const MAXLENGTH = 3;
const Score = ({
  info, setInfo, onSubmit, submitScoreLoading,
}) => {
  const [selectedContent, setSelectContent] = useState();

  const handleChange = ({ target }) => {
    setInfo((prevValue) => ({
      ...prevValue,
      name: target.value.slice(0, target.maxLength),
    }));
  };

  const handleSelect = (elemId) => {
    setSelectContent(elemId);
    setInfo((prevValue) => ({ ...prevValue, content: elemId }));
  };

  return (
    <Background>
      <div>
        <QuestionTitle>
          Complete!
        </QuestionTitle>
        <PersonalScore>
          Score:
          {' '}
          {info.score}
        </PersonalScore>
        <Subtitle>
          Enter your name
        </Subtitle>
        <InputContainer>
          <InputName
            id="scoreName"
            type="text"
            maxLength={MAXLENGTH}
            onChange={handleChange}
            value={info.name || ''}
          />
          <WhiteLine left={4} />
          <WhiteLine left={37} />
          <WhiteLine left={71} />
        </InputContainer>
        <Subtitle>
          What type of content would you like to see more of on the tablet?
        </Subtitle>
        <ScoreOptContainer>
          <ContentOption onClick={() => handleSelect('games')}>
            {selectedContent === 'games' && <SelectArrow id="games" />}
            Games
          </ContentOption>
          <ContentOption onClick={() => handleSelect('sports')}>
            {selectedContent === 'sports' && <SelectArrow id="sports" />}
            Sports
          </ContentOption>
          <ContentOption onClick={() => handleSelect('news')}>
            {selectedContent === 'news' && <SelectArrow id="news" />}
            News
          </ContentOption>
          <ContentOption onClick={() => handleSelect('riderGeneratedContent')}>
            {selectedContent === 'riderGeneratedContent' && <SelectArrow id="riderGeneratedContent" />}
            Rider generated content
          </ContentOption>
          <ContentOption onClick={() => handleSelect('giveawaysAndPromos')}>
            {selectedContent === 'giveawaysAndPromos' && <SelectArrow id="giveawaysAndPromos" />}
            Giveaways and promos
          </ContentOption>
        </ScoreOptContainer>
        {(submitScoreLoading)
          ? (
            <SubmitLoadingContainer>
              <ClipLoader loading={submitScoreLoading} color="#ffffff" size={35} />
            </SubmitLoadingContainer>
          )
          : <HighScoreBtn onClick={onSubmit} />}
      </div>
      <Footer />
    </Background>
  );
};

const RightCloud = styled(Cloud)`
    top: 62px;
    right: -51px;
    left: auto;
`;

const Ranked = styled.div`
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.89);
    font-size: 20px;
    margin-top: 5vh;
`;

const RankSubtitle = styled.div`
    font-size: 20px;
    margin-top: 5vh;
`;

const TableItem = styled.div`
    display: grid;
    grid-template-columns: 64px 137px 151px;
    font-size: 16px;
    text-align: right;
    margin: 1vh auto 0;
    width: fit-content;
    ${({ highLight }) => highLight && 'color: #c40000;'}
`;

const TableTitle = styled(TableItem)`
    margin-top: 4vh;
    margin-bottom: 1.5vh;
`;

// const ShareTip = styled.div`
//     width: 342px;
//     text-shadow: 0 3px 0 rgba(0, 0, 0, 0.89);
//     font-size: 16px;
//     line-height: 1.56;
//     margin: 3.5vh auto 0;
// `

// const ShareBtn = styled.div`
//     width: 269px;
//     height: 79px;
//     background-image: url('/assets/quizShare.png');
//     background-size: cover;
//     margin: 4vh auto 0;
// `

const SocialMediaWapper = styled(SocialMediaIcons)`
    margin-top: 32px;
`;

const PortlWrapper = styled(PortlCopyRight)`
    margin-top: 16px;
`;

const Rank = ({ ranks }) => (
  <Background>
    <div>
      <Cloud src="/assets/quizCloud.png" />
      <RightCloud src="/assets/quizCloud.png" />
      <Title>
        Quizzle
        <TitlePortl>by portl</TitlePortl>
      </Title>
      <Ranked>
        You rank
        {' '}
        {ordinal(ranks.position + 1)}
        !
      </Ranked>
      <RankSubtitle>
        High Scores
      </RankSubtitle>
      <TableTitle>
        <div>Rank</div>
        <div>Name</div>
        <div>Score</div>
      </TableTitle>
      {ranks.top10.map((element, i) => (
        <TableItem key={element.score + element.name} highLight={i === ranks.position}>
          <div>{ordinal(i + 1)}</div>
          <div>{element.name}</div>
          <div>{element.score}</div>
        </TableItem>
      ))}
      {/* <ShareTip>
                Share your score on social media!
            </ShareTip>
            <ShareBtn /> */}
      <SocialMediaWapper />
      <PortlWrapper />
    </div>
    <Footer />
  </Background>
);

const Quizzle = ({ privateCode, tabletIMEI }) => {
  const [status, setStatus] = useState();
  const [isSocketConnect, setSocketConnection] = useState(false);
  const [question, setQuestion] = useState(0);
  const [total, setTotal] = useState(0);
  const [info, setInfo] = useState({ name: '', score: 0, content: '' });
  const [ranks, setRanks] = useState();
  const [submitScoreLoading, setSubmitScoreLoading] = useState(false);

  const socketRef = useRef(initConnection());
  const query = window.location.pathname.split('/');
  const code = privateCode || query.pop();
  const IMEI = tabletIMEI || query.pop();

  useEffect(() => {
    const socket = socketRef.current;
    return () => socket.emit('desactiveGameListeners');
  }, []);

  const toggleScoreLoading = () => {
    setSubmitScoreLoading((prevState) => !prevState);
  };

  useEffect(() => {
    const socket = socketRef.current;

    socket.on('tabletConnected', () => {
      setSocketConnection(true);
    });
    socket.on('questionNumber', (number) => {
      setQuestion(number);
    });
    socket.on('scoreInfo', (tabletInfo) => {
      setInfo({ ...tabletInfo });
      setStatus('score');
    });
    socket.on('leaderboard', ({ top10, position }) => {
      toggleScoreLoading();
      setRanks({ top10, position });
      setStatus('rank');
    });
    socket.on('notConnected', (msg) => {
      setSocketConnection(false);
      console.log(msg);
    });

    socket.emit('joinGame', { code, IMEI });

    socket.on('disconnect', () => {
      setSocketConnection(false);
    });

    return () => {
      socket.off('tabletConnected');
      socket.off('questionNumber');
      socket.off('notConnected');
      socket.off('quizStarted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStart = () => {
    socketRef.current.on('quizStarted', (totalQuestions) => {
      setTotal(totalQuestions);
      setStatus('question');
    });
    socketRef.current.emit('startQuiz');
  };

  const handleOption = (opt) => {
    socketRef.current.emit('answerQuestion', { question, opt });
  };

  const handleSubmission = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleScoreLoading();
    socketRef.current.emit('persistScore', info);
  };

  switch (status) {
    case 'question':
      return <Question onOption={handleOption} question={question} total={total} />;
    case 'score':
      return (
        <Score
          info={info}
          setInfo={setInfo}
          onSubmit={handleSubmission}
          submitScoreLoading={submitScoreLoading}
        />
      );
    case 'rank':
      return <Rank ranks={ranks} />;
    default:
      return <Start onStart={handleStart} isSocketConnect={isSocketConnect} />;
  }
};

export default Quizzle;
