import React, { useState, useEffect, useRef } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import Cookies from 'js-cookie';

// components
import PortlLogo from '../../components/PortlLogo';
import InfoTimeout from '../../components/InfoTimeout';
import DynamicComponent from '../../components/DynamicComponent';

// compositions
import TabletSettingControl from '../../compositions/TabletSettingControl';

// utils
import { getTiles } from '../../utils/api';
import { initConnection } from '../../utils/socket';

const CODE_LENGTH = 4;

const Background = styled.div`
    min-height: 100vh;
    background-color: #e7e7fb;
    overflow-x: scroll;
    position: relative;
`;

const BackgroundOverlay = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    backdrop-filter: blur(25px);
    transition: all 0.2s ease;
    background: rgb(0, 0, 0, 0.7);
    z-index: 3;
`;

const CodeWrapper = styled.div`
    max-width: 342px;
    height: 394px;
    margin: 40px auto 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #006abb;
    padding: 40px 37px 28px;
`;

const CodeText = styled.p`
    font-size: 18px;
    line-height: 1.39;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 18px 0 0;
`;

const ReactCodeInputWrapper = styled(ReactCodeInput)`
    margin: 23px auto 0;

    input {
        border: solid 1px #006abb; 
        border-radius: 0 !important;
    }
`;

const HourGlass = styled.div`
    width: 53px;
    height: 53px;
    background-image: url('/assets/hourglassIcon.png');
    background-size: cover;
    margin: 0 auto;
`;

const PortlLogoWrapper = styled(PortlLogo)`
    margin-top: 12px;
    margin-left: 36px;
`;

const SuccessIcon = styled.div`
    background-image: url('/assets/successIcon.png');
    background-size: cover;
    height: 41px;
    flex: 0 0 41px;
    margin-right: 15px;
`;

const ConnectionMessage = styled.div`
    display: flex;
    align-items: center;
`;

const Info = styled.div`
    line-height: 1.56;
`;

const InfoTitle = styled.div`
    font-weight: 600;
`;

const TilesContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const Tiles = styled.img`
    width: 342px;
    height: 288px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

const SettingBackground = styled.div`
    width: 100%;
    height: 69px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const SettingWrapper = styled.div`
    width: 414px;
    height: 100%;
    padding: 0 17px;
    display: flex;
    justify-content: space-evenly;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #404040;
`;

const Icon = styled.div`
    background-image: url('${(props) => props.src}');
    background-size: cover;
    width: 36px;
    height: 36px;
`;

const Space = styled.div`
    height: 69px;
`;

const Settings = ({ setShowSettings, onHomeClick }) => (
  <SettingBackground>
    <SettingWrapper>
      <IconWrapper onClick={onHomeClick}>
        <Icon src="/assets/homeIcon.jpg" />
        Home
      </IconWrapper>
      <IconWrapper onClick={() => setShowSettings((prev) => !prev)}>
        <Icon src="/assets/settingIcon.jpg" />
        Tablet Settings
      </IconWrapper>
    </SettingWrapper>
  </SettingBackground>
);

const TabletRemoteControl = () => {
  const [tiles, setTiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [tabletIMEI, setTabletIMEI] = useState();
  const [activeTile, setActiveTile] = useState();
  const [showSettings, setShowSettings] = useState(false);
  const [tilePrivateCode, setTilePrivateCode] = useState();

  const socket = useRef(initConnection());
  const history = useHistory();

  const handleOnIdle = () => {
    socket.current.emit('endSession');
  };

  const { getTotalActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 2,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchTiles = async () => {
      const response = await getTiles();

      if (response.error) return history.push('/'); // redirect the user to the reScan again page.

      if (isMounted) return setTiles(response);
      return null;
    };

    fetchTiles();

    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // socket config
  useEffect(() => {
    const currentSocket = socket.current;

    currentSocket.on('tabletNotFound', () => {
      history.push('/');
    });

    currentSocket.on('sessionStart', (IMEI) => {
      setTabletIMEI(IMEI);
      toast.success(
        <ConnectionMessage>
          <SuccessIcon />
          <Info>
            <InfoTitle>
              Connection successful!
            </InfoTitle>
            Touch a tile below and then refer to the tablet for more information.
          </Info>
        </ConnectionMessage>,
      );
    });

    currentSocket.once('sessionEnded', () => {
      mixpanel.track('RemoteControl', { IMEI: tabletIMEI, activeTime: getTotalActiveTime() });
      socket.current.disconnect();
      history.push('/');
    });

    currentSocket.on('sendRenewCode', (code) => {
      setShowCode(code);
    });

    currentSocket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') return history.push('/');

      console.log('Socket disconnected, reason', reason);
      return null;
      // else the socket will automatically try to reconnect
    });

    currentSocket.on('endGame', () => {
      setActiveTile(false);
    });

    currentSocket.emit('tabletControl', Cookies.get('tokenControl'));

    return () => {
      currentSocket.off('endGame');
      currentSocket.off('appOpened');
      currentSocket.off('homeOpened');
      currentSocket.off('sessionStart');
      currentSocket.off('sessionEnded');
      currentSocket.off('settingChanged');
      currentSocket.off('tabletNotFound');
      currentSocket.off('receivedRenewCode');

      currentSocket.emit('endSession');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeTile]);

  const handleTilesClick = async (tileName) => {
    if (loading) return;
    setLoading(true);

    socket.current.once('appOpened', (openedTile, tileCode) => {
      setLoading(false);
      setActiveTile(openedTile);
      setTilePrivateCode(tileCode);
    });

    socket.current.emit('goToApp', tileName);
  };

  const handleHomeClick = async () => {
    if (loading) return;
    setLoading(true);

    socket.current.once('homeOpened', () => {
      setLoading(false);
      setActiveTile(false);
    });

    socket.current.emit('goToHome');
  };

  const handleRenewCode = (inputCode) => {
    if (inputCode === showCode) {
      setShowCode(false);
      socket.current.emit('renewConfirmed');
    }
  };

  return (
    <Background>
      {(showCode)
        && (
          <BackgroundOverlay>
            <CodeWrapper>
              <HourGlass />
              <CodeText>
                Your mobile Portl session has timed out.
              </CodeText>
              <CodeText>
                In order to continue please enter the code
                being displayed on the tablet screen below.
              </CodeText>
              <ReactCodeInputWrapper fields={CODE_LENGTH} onComplete={handleRenewCode} />
            </CodeWrapper>
          </BackgroundOverlay>
        )}
      <TabletSettingControl
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        socket={socket.current}
      />
      {
        (activeTile)
          ? <DynamicComponent path={`views/${activeTile}`} privateCode={tilePrivateCode} tabletIMEI={tabletIMEI} key={activeTile} />
          : (
            <>
              <PortlLogoWrapper />
              <TilesContainer>
                {(tabletIMEI)
                  ? tiles.map((e) => (e.name !== 'RemoteControl') && <Tiles src={e.imageUrl} onClick={() => handleTilesClick(e.name)} key={e.imageUrl} />)
                  : <InfoTimeout />}
              </TilesContainer>
            </>
          )
      }
      <Space />
      <Settings setShowSettings={setShowSettings} onHomeClick={handleHomeClick} />
    </Background>
  );
};

export default TabletRemoteControl;
