import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    font-family: 'Lato', sans-serif;
    color: #1f2639;
`;

const BoardInfo = styled.div`
    margin: 16px auto 0;
    max-width: 1075px;
    font-size: 18px;
    text-align: center;
`;

const InputContainer = styled.div`
    position: relative;
    width: fit-content;
    margin: 0 auto;
`;

const InputName = styled.input`
    letter-spacing: 10px;
    text-indent: 5px;
    background: transparent;
    width: 105px;
    border: none;
    font-size: 33px;
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
`;

const Line = styled.div`
    width: 27px;
    height: 2px;
    background-color: #1f2639;
    position: absolute;
    bottom: 4px;
    left: ${({ left }) => left}px;
`;

const ScoreOptContainer = styled.div`
    margin: 16px auto 0;
`;

const ContentOption = styled.div`
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${({ checked }) => (checked ? '#88ccff' : '#fafafa')};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 10px;
    font-size: 16px;
    margin-bottom: 9px;
`;

const NextButton = styled.div`
    margin: 21px auto 0;
    border-radius: 10px;
    background-color: ${({ color }) => color || '#1f2639'};
    color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 25px;
    font-weight: bold;
`;

const QuizNameSubTemplate = ({
  themeColor, info, setInfo, stdColor, onSubmit,
}) => {
  const [buttonColor, setButtonColor] = useState(themeColor);

  const handleChange = ({ target }) => {
    const newValue = target.value.slice(0, target.maxLength);
    setInfo((prevValue) => ({ ...prevValue, name: newValue.toUpperCase() }));
  };

  const handleSelect = (elemId) => {
    setInfo((prevValue) => ({ ...prevValue, content: elemId }));
  };

  const handleSubmit = (e) => {
    if (buttonColor !== themeColor) return;

    setButtonColor('#7E7E7E');
    onSubmit(e);
  };

  return (
    <>
      <Wrapper>
        <BoardInfo>
          Please enter your initials:
        </BoardInfo>
        <InputContainer>
          <InputName
            id="scoreName"
            type="text"
            maxLength={3}
            onChange={handleChange}
            value={info.name}
          />
          <Line color={stdColor} left={4} />
          <Line color={stdColor} left={35} />
          <Line color={stdColor} left={66} />
        </InputContainer>
        <BoardInfo>
          What type of content would you like to see more of on the tablet?
        </BoardInfo>
        <ScoreOptContainer>
          <ContentOption onClick={() => handleSelect('games')} checked={info.content === 'games'}>
            Games
          </ContentOption>
          <ContentOption onClick={() => handleSelect('news')} checked={info.content === 'news'}>
            News
          </ContentOption>
          <ContentOption onClick={() => handleSelect('sports')} checked={info.content === 'sports'}>
            Sports
          </ContentOption>
          <ContentOption onClick={() => handleSelect('giveawaysAndPromos')} checked={info.content === 'giveawaysAndPromos'}>
            Giveaways and promos
          </ContentOption>
          <ContentOption onClick={() => handleSelect('riderGeneratedContent')} checked={info.content === 'riderGeneratedContent'}>
            Rider generated content
          </ContentOption>
        </ScoreOptContainer>
      </Wrapper>
      <NextButton color={buttonColor} onClick={handleSubmit}>
        See Your Ranking!
      </NextButton>
    </>
  );
};

export default QuizNameSubTemplate;
