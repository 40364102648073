import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { isProduction } from './config';

const GlobalStyle = createGlobalStyle`
    
    //html {
      //  overflow: auto;     /*                                       */
        //height: 100%;       /*   Do not combine with body,           */
       // overflow-x: hidden; /*    need for autoscroll to work in app */
    //}

    html, body {
       // border: none;
        //outline: none;
         //overflow-x: hidden;
        //scrollbar-width: none;
       // scroll-behavior: smooth !important;
        margin: 0;
        padding: 0;
        //top: 0;
        //left: 0;
        width: 100%;
        height: 100%;
        //z-index: -1;
    }
    
    body {
        font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        color: #222222;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //text-align: center;
    }

    * {
        box-sizing: border-box;
        scrollbar-width: none !important;
    }

    ::-webkit-scrollbar { // Hide all the scrollbars on the website 
        display: none;
    }

    button, input, textarea { //added to have as default those css, otherwise it will need to change the css of button components everytime. eg. calendar hours.
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    p, h1, h2, h3, h4, h5 { // added because all the p and h tags have margin top and botton as default. eg. book confirmation.
        margin: 0;
        padding: 0;
    }

    p {
        line-height: 1.5;
    }

    a {
        text-decoration: none;
    }
`;

if (isProduction) {
  mixpanel.init('c16aa89e5ccb82ea62c9f983a915e7be');
} else {
  // Test token
  mixpanel.init('ab483d9d56a863b06ca6010998a861f7');
  // staging, test, dev, localhost
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
