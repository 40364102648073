import React from 'react';
import styled from 'styled-components';

// component
import PortlLogo from '../../components/PortlLogo';
import InfoTimeout from '../../components/InfoTimeout';

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 12px 36px 0;
    background-color: #e7e7fb;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
`;

const Rescan = () => (
  <>
    <Background>
      <PortlLogo />
      <InfoTimeout />
    </Background>
  </>
);

export default Rescan;
