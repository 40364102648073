const randomInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

export const generateCodeString = (size) => {
  let newCode = '';

  for (let i = 0; i < size; i += 1) {
    newCode += String.fromCharCode(randomInt(65, 91));
  }
  return newCode;
};

export const generateCodeInt = (size) => {
  let newCode = '';

  for (let i = 0; i < size; i += 1) {
    newCode += randomInt(0, 10);
  }
  return newCode;
};

export const returnDateOneYearFromNow = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year + 1, month, day);
};

export const returnDateOneDayFromNow = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year, month, day + 1);
};

export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
