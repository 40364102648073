import React, { useState, useRef, useEffect } from 'react';
// utils
import { initConnection } from '../../utils/socket';
import { useRotateDeviceOverlay } from '../../utils/customHooks';

const FlipGame = ({
  StartComponent,
  WinComponent,
  GameComponent,
  tabletIMEI,
  LoseComponent,
}) => {
  const [status, setStatus] = useState('');
  const [tiles, setTiles] = useState();
  const [disableClick, setDisableClick] = useState(false);
  const code = useRef();

  const socketRef = useRef(initConnection());
  const query = window.location.pathname.split('/');
  const IMEI = tabletIMEI || query.pop();

  const RotateOverlay = useRotateDeviceOverlay();

  const playAgain = (tilesReceived) => {
    setTiles(tilesReceived);
    setDisableClick(false);
    setStatus('game');
  };

  useEffect(() => {
    const socket = socketRef.current;
    return () => socket.emit('desactiveGameListeners');
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on('flipGameRestarted', (tilesReceived) => {
      playAgain(tilesReceived);
    });

    socket.on('gameFlipStarted', ({ tiles: newTiles, code: newCode }) => {
      code.current = newCode.current;
      setTiles(newTiles);
      setStatus('game');
    });

    let id;
    socket.on('flipLostTileFound', () => {
      setDisableClick(true);
      id = setTimeout(() => setStatus('lose'), 4000);
    });

    socket.on('flipGameWon', () => {
      id = setTimeout(() => setStatus('win'), 4000);
      setDisableClick(true);
    });

    socket.on('disconnect', () => {
      console.log('disconnect');
    });

    socket.on('notConnected', (msg) => {
      console.log(msg);
    });

    socket.emit('joinGame', { code, IMEI });

    return () => {
      socket.off('notConnected');
      socket.off('disconnect');
      socket.off('flipGameRestarted');
      socket.off('gameFlipStarted');
      socket.off('flipLostTileFound');
      if (id) clearTimeout(id);
    };
    // eslint-disable-next-line
  }, [socketRef.current]);

  const handleStart = () => {
    socketRef.current.emit('startFlipGame');
  };

  const handleTileClick = (tile, rowIndex, columnIndex) => {
    setTiles((prevSquares) => {
      const newSquares = [...prevSquares];
      newSquares[rowIndex][columnIndex] = {
        ...tile,
        show: true,
      };
      return newSquares;
    });

    socketRef.current.emit('tileClicked', { tile, rowIndex, columnIndex });
  };

  const handlePlayAgain = (e) => {
    e.stopPropagation();
    e.preventDefault();

    socketRef.current.emit('playAgainFlip');
  };

  const renderSwitch = () => {
    switch (status) {
      case 'game': return (
        <GameComponent
          handleTileClick={handleTileClick}
          tiles={tiles}
          disableClick={disableClick}
        />
      );
      case 'win': return <WinComponent IMEI={IMEI} code={code.current} />;
      case 'lose': return <LoseComponent handlePlayAgain={handlePlayAgain} />;
      default:
        return <StartComponent handleClick={handleStart} />;
    }
  };

  return (
    <>
      {RotateOverlay}
      {renderSwitch()}
    </>
  );
};

export default FlipGame;
