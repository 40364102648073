import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FlipGame from '../FlipGame';

const Background = styled.div`
    position: relative;
    background: url('/assets/FourLeafFortuneBG2.webp') center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    ${({ disableClick }) => disableClick && 'pointer-events: none;'}
`;
const WinBackground = styled(Background)`
    align-items: flex-start;
`;
const StartText = styled.div`
    max-width: 450px;
    padding-top: 125px;
    padding-bottom: 28px;
    font-family: Montserrat;
    font-size: 30px;
    line-height: 1.23;
    text-align: center;
    color: #ffffff;
`;
const StartButton = styled.button`
    width: 250px;
    height: 65px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.63);
    border-radius: 10px;
    border: solid 4px #0b0f33;
    background-color: #2b7c20;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }
`;
const StartButtonText = styled.div`
    font-size: 32px;
    font-family: CryUncial;
    color: #ffffff;
    text-transform: uppercase;
    padding-right: 20px;
`;
const CloverImg = styled.img`
    width: 80px;
    height: 42px;
`;
const CongratsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 80px;
    padding-left: 58px;

    @media (min-width: 699px) {
        padding-top: 112px;
    }
`;
const CongratsHeader = styled.div`
    font-family: CryUncial;
    font-size: 30px;
    text-shadow: 0 10px 10px rgba(0, 0, 0, 0.64);
    text-align: left;
    color: #ffffff;
`;
const CongratsText = styled.div`
    font-family: FuturePT-Book;
    font-size: 18px;
    text-align:left;
    text-shadow: 0 8px 6px rgba(0, 0, 0, 0.72);
    line-height: 18px;
    color: #f1f8ff;
    max-width: 400px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: 720px) {
        max-width: 580px;
    }
`;
const ClaimButton = styled(StartButton)`
    width: 190px;
    height: 50px;
    font-family: CryUncial;
    font-size: 22px;
    color: #ffffff;
`;
const EmptyMugImg = styled.img`
    height: 148px;
    width: 148px;
    position: absolute;
    top: 70px;
    right: 35px;
`;
const DripImg = styled.img`
    height: 26px;
    width: 14px;
    position: absolute;
    top: 180px;
    right: 121px;
`;
const GameContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const GameGrid = styled.div`
    position: relative;
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(5, 1fr);
    min-width: 360px;
    margin: 0 auto;
    padding-top: 95px;
`;
const EmptyDiv = styled.div`
    height: 80px;
    width: 80px;
`;
const Coaster = styled.img`
    height: 88px;
    width: 88px;
`;
const LeprechaunImg = styled.img`
    width: 192px;
    height: 213px;
    position: absolute;
    top: 98px;
    right: 40px;

    @media (min-width: 600) {
        right: 80px;
    }
`;

const StartComponent = ({ handleClick }) => (
  <Background>
    <StartText>
      The rules are displayed on the tablet.
      Press
      {' '}
      <b>start</b>
      {' '}
      when you are ready to play!
    </StartText>
    <StartButton onClick={handleClick}>
      <StartButtonText>start</StartButtonText>
      <CloverImg src="/assets/threeclovers.webp" />
    </StartButton>
  </Background>
);

const WinComponent = ({ IMEI, code }) => {
  const history = useHistory();
  return (
    <WinBackground>
      <CongratsContainer>
        <CongratsHeader>Congratulations!</CongratsHeader>
        <CongratsText>
          Turns out you have the luck of the Irish!
          <br />
          <br />
          On behalf of Portl, we&apos;d like to congratulate you for winning Four Leaf Fortune!
          <br />
          <br />
          Click below to claim your prize, and happy St.Patrick&apos;s Day to ye!
        </CongratsText>
        <ClaimButton onClick={() => history.push(`/FourLeafFortuneWin/${IMEI}/${code}`)}>Claim Prize</ClaimButton>
      </CongratsContainer>
      <EmptyMugImg src="/assets/glass_empty.webp" />
      <DripImg src="/assets/drip.webp" />
    </WinBackground>
  );
};

const Tile = ({ show, onHiddenTileClick }) => {
  if (!show) {
    return (
      <Coaster
        src="/assets/coaster_back.png"
        onClick={onHiddenTileClick}
      />
    );
  }
  return <EmptyDiv />;
};

const LoseScreen = ({ handlePlayAgain }) => (
  <WinBackground>
    <CongratsContainer>
      <CongratsHeader>Bollocks!!!</CongratsHeader>
      <CongratsText>
        Looks like the Leprechaun got you before your finished your beer.
        <br />
        <br />
        Don&apos;t worry, there&apos;s plenty more beer inside.
        <br />
        <br />
        Are you still thirsty?
      </CongratsText>
      <ClaimButton onClick={handlePlayAgain}>Play Again</ClaimButton>
    </CongratsContainer>
    <LeprechaunImg src="/assets/mean_muggin.webp" />
  </WinBackground>
);

const GameComponent = ({
  tiles,
  handleTileClick,
  disableClick,
}) => (
  <Background disableClick={disableClick}>
    <GameContainer>
      <GameGrid>
        {tiles && tiles.map((row, rowIndex) => row.map((tile, columnIndex) => {
          const key = tile.value + rowIndex + columnIndex;
          return (
            <Tile
              key={key}
              onHiddenTileClick={() => handleTileClick(tile, rowIndex, columnIndex)}
              value={tile.value}
              show={tile.show}
            />
          );
        }))}
      </GameGrid>
    </GameContainer>
  </Background>
);

const FourLeafFortune = ({ privateCode, tabletIMEI }) => (
  <FlipGame
    privateCode={privateCode}
    tabletIMEI={tabletIMEI}
    StartComponent={StartComponent}
    WinComponent={WinComponent}
    GameComponent={GameComponent}
    winCondition={3}
    LoseComponent={LoseScreen}
  />
);

export default FourLeafFortune;
